import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import LayoutProcessometro from "../Layout/LayoutProcessometro.vue";
import Processometro from "../views/Processometro/Processometro.vue";
import AnalisePreditiva from "../views/Processometro/AnalisePreditiva.vue";
import ProcessoMateria from "../views/Processometro/ProcessosMateria.vue";
import PerfilJuridico from "../views/Processometro/PerfilJuridico.vue";

import LayoutQuiz from "../Layout/LayoutQuiz.vue";
import HomeQuiz from "../views/Quiz/HomeQuiz.vue";
import ApresentacaoQuiz from "../views/Quiz/ApresentacaoQuiz.vue";
import CadastroQuiz from "../views/Quiz/CadastroQuiz.vue";
import PerguntasQuiz from "../views/Quiz/PerguntasQuiz.vue";
import ResultadoQuiz from "../views/Quiz/ResultadoQuiz.vue";
import Admin from "../views/Admin/Login.vue";
import LayoutAdmin from "@/Layout/LayoutAdmin.vue";
import Login from "../views/Admin/Login.vue";

const routes = [
  { path: "/", redirect: "/processometro" },
  {
    path: "/",
    name: "home",
    component: LayoutProcessometro,
    children: [
      {
        path: "/processometro",
        name: "nossosNumeros",
        component: Processometro,
        meta: { requiresAuth: false },
      },
      {
        path: "/perfil-juridico",
        name: "perfilJuridico",
        component: PerfilJuridico,
        meta: { requiresAuth: false },
      },
      {
        path: "/analise-preditiva",
        name: "analisePreditiva",
        component: AnalisePreditiva,
        meta: { requiresAuth: false },
      },
      {
        path: "/processos-por-materia",
        name: "distribuicoesMateria",
        component: ProcessoMateria,
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: "/quiz",
    name: "quiz",
    component: LayoutQuiz,
    children: [
      {
        path: "/quiz",
        name: "homeQuiz",
        component: HomeQuiz,
      },
      {
        path: "/quiz/apresentacao",
        name: "apresentacaoQuiz",
        component: ApresentacaoQuiz,
      },
      {
        path: "/quiz/cadastro",
        name: "cadastroQuiz",
        component: CadastroQuiz,
      },
      {
        path: "/quiz/perguntas",
        name: "perguntasQuiz",
        component: PerguntasQuiz,
      },
      {
        path: "/quiz/resultado",
        name: "resultadoQuiz",
        component: ResultadoQuiz,
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    component: LayoutAdmin,
    children: [
      {
        path: "/admin/login",
        name: "login",
        component: Login,
      },
      {
        path: "/admin/dadosCadastros",
        name: "dadosCadastros",
        component: () => import("../views/Admin/DadosCadastros.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.getToken) {
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
