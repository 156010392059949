<template>
  <div class="tela-cadastro">
    <header>
      <div>DESCUBRA O GRAU DE MATURIDADE DIGITAL DA SUA EMPRESA</div>
    </header>

    <section>
      <h1 class="titulo">
        Antes de tudo, deixe aqui o seu melhor e-mail. <br />
        Ao concluir a análise, iremos mandar o resultado para ele.
      </h1>

      <div class="area-formulario">
        <form action="" class="formulario">
          <div class="area-input">
            <label for="nome" class="label"> Nome * </label>
            <input
              v-model="form.nome"
              type="text"
              name="nome"
              placeholder="Nome"
              class="input"
              autocomplete="off"
            />
            <span class="erro" v-if="erro.nome">
              O campo nome é obrigatório
            </span>
          </div>

          <div class="area-input">
            <label for="nome" class="label"> Email * </label>
            <input
              v-model="form.email"
              type="email"
              name="email"
              placeholder="Seu Email"
              class="input"
              autocomplete="off"
              @blur="validarEmailCadastrado()"
            />
            <span class="erro" v-if="erro.email">
              O campo email é obrigatório
            </span>
            <span class="erro" v-if="erro.emailInvalido">
              Insira um email válido
            </span>
            <span class="email-cadastrado" v-if="erro.emailCadastrado">
              Esse email já foi cadastrado
            </span>
          </div>

          <div class="area-input">
            <label for="nome" class="label"> Telefone </label>

            <div class="area-input-telefone">
              <input
                v-model="form.ddi"
                type="text"
                name="telefone"
                placeholder="Cód País"
                class="input-ddi"
                autocomplete="off"
                v-mask="'+##'"
                maxlength="3"
                disabled
              />

              <input
                v-model="form.telefone"
                type="text"
                name="telefone"
                placeholder="Telefone"
                class="input-telefone"
                v-mask="'(##) #####-####'"
                autocomplete="off"
              />
            </div>
            <!-- <span class="erro" v-if="erro.telefone">
              O campo telefone é obrigatório
            </span> -->
            <span class="erro" v-if="erro.telefoneInvalido">
              Insira um telefone válido
            </span>
          </div>

          <div class="area-input">
            <label for="empresa" class="label"> Empresa * </label>
            <input
              v-model="form.empresa"
              type="text"
              name="empresa"
              placeholder="Empresa"
              class="input"
              autocomplete="off"
            />
            <span class="erro" v-if="erro.empresa">
              O campo empresa é obrigatório
            </span>
          </div>

          <div class="area-input">
            <label for="cargo" class="label"> Cargo </label>

            <select v-model="form.cargo" name="cargo" id="cargo" class="input">
              <option value="" disabled selected>Selecione uma opção</option>

              <template v-for="cargo in cargos" :key="cargo">
                <option :value="cargo">{{ cargo }}</option>
              </template>
            </select>
            <!-- <span class="erro" v-if="erro.cargo">
              O campo cargo é obrigatório
            </span> -->
          </div>

          <div class="area-input">
            <label for="num-funcionarios" class="label">
              Número de Funcionários
            </label>

            <select
              v-model="form.numeroFuncionarios"
              name="num-funcionarios"
              id="num-funcionarios"
              class="input"
            >
              <option value="" disabled selected>Selecione uma opção</option>

              <template
                v-for="numFuncionario in numeroFuncionarios"
                :key="numFuncionario"
              >
                <option :value="numFuncionario">{{ numFuncionario }}</option>
              </template>
            </select>

            <!-- <span class="erro" v-if="erro.numeroFuncionarios">
              O campo número de funcionários é obrigatório
            </span> -->
          </div>

          <div class="area-input">
            <a-checkbox v-model:checked="form.termos" />
            <span class="termos">
              Ao preencher o formulário acima, o Participante declara estar
              ciente e concorda que seus dados serão tratados pela Kurier com a
              finalidade do envio do resultado do Perfil do Grau de Maturidade
              Digital, bem como possíveis comunicações e ações promocionais, e
              não serão compartilhados com terceiros. Para mais informações,
              consulte nossa Política de Privacidade em nosso site:
              <a
                style="color: #fff; text-decoration: none"
                href="www.kurier.com.br"
                target="_blanc"
              >
                www.kurier.com.br </a
              >. A qualquer momento, você poderá revogar seu consentimento
              através do canal de 'titular de dados' no site da Kurier ou na
              opção de 'cancelamento de subscrição' em nossas
              comunicações.</span
            >

            <div class="erro" v-if="erro.termos">
              É necessário aceitar os termos para prosseguir
            </div>
          </div>
        </form>
      </div>
    </section>

    <footer>
      <BotaoQuiz :disabled="consultando" @click="comecar()">
        Próximo! <a-spin v-if="consultando" :spinning="true" />
      </BotaoQuiz>
    </footer>
  </div>
</template>

<script>
import BotaoQuiz from "@/components/BotaoQuiz.vue";
import api from "@/api";
import { message } from "ant-design-vue";

export default {
  components: {
    BotaoQuiz,
  },

  data() {
    return {
      consultando: false,
      erro: {
        nome: false,
        email: false,
        ddi: false,
        telefone: false,
        empresa: false,
        cargo: false,
        numeroFuncionarios: false,
        emailInvalido: false,
        telefoneInvalido: false,
        emailCadastrado: false,
        termos: false,
      },

      form: {
        nome: "",
        email: "",
        ddi: 55,
        telefone: "",
        empresa: "",
        cargo: "",
        numeroFuncionarios: "",
        termos: false,
      },

      cargos: [
        "Sócio/C-Level",
        "Diretor Jurídico",
        "Diretor Financeiro",
        "Diretor de TI",
        "Diretor de outras áreas",
        "Gerente/Coordenador Jurídico",
        "Gerente/Coordenador Financeiro",
        "Gerente/Coordenador de TI",
        "Gerente/Coordenador de outras áreas",
        "Legal Operation",
        "Advogado",
        "Assistente/Analista Jurídico",
        "Assistente/Analista Financeiro",
        "Analista de TI",
        "Analista de outras áreas",
        "Estudante/Estagiário",
        "Outro",
      ],

      numeroFuncionarios: [
        "1 a 10",
        "11 a 50",
        "51 a 100",
        "101 a 500",
        "Acima de 500",
      ],
    };
  },

  watch: {
    "form.nome": function () {
      this.removerErroValidacao("nome");
    },

    "form.email": function () {
      this.removerErroValidacao("email");
      this.removerErroValidacao("emailInvalido");
      this.removerErroValidacao("emailCadastrado");
    },

    "form.empresa": function () {
      this.removerErroValidacao("empresa");
    },

    "form.termos": function () {
      this.removerErroValidacao("termos");
    },
  },

  mounted() {
    window.history.replaceState(null, null, "/");
  },

  methods: {
    comecar() {
      if (this.validarCamposObrigatorios()) {
        this.$store.dispatch("salvarCadastroAtual", this.form);

        this.$router.push({ name: "perguntasQuiz" });
      }
    },

    validarEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    validarTelefone(telefone) {
      const re = /\(\d{2}\) \d{5}-\d{4}/;
      return re.test(telefone);
    },

    removerErroValidacao(campo) {
      this.erro[campo] = false;
    },

    validarCamposObrigatorios() {
      const camposObrigatorios = [
        "nome",
        "email",
        //"ddi",
        //"telefone",
        "empresa",
        //"cargo",
        //"numeroFuncionarios",
        "termos",
      ];

      camposObrigatorios.forEach((campo) => {
        if (!this.form[campo]) {
          this.erro[campo] = true;
        } else {
          this.erro[campo] = false;
        }

        if (campo == "email" && this.form[campo]) {
          if (!this.validarEmail(this.form.email)) {
            this.erro.emailInvalido = true;
          } else {
            this.erro.emailInvalido = false;
          }
        }

        if (campo == "telefone" && this.form[campo]) {
          if (!this.validarTelefone(this.form.telefone)) {
            this.erro.telefoneInvalido = true;
          } else {
            this.erro.telefoneInvalido = false;
          }
        }
      });

      return (
        !this.erro.nome &&
        !this.erro.email &&
        !this.erro.ddi &&
        !this.erro.telefone &&
        !this.erro.empresa &&
        !this.erro.cargo &&
        !this.erro.numeroFuncionarios &&
        !this.erro.emailInvalido &&
        !this.erro.telefoneInvalido &&
        !this.erro.emailCadastrado &&
        !this.erro.termos
      );
    },

    validarEmailCadastrado() {
      if (this.validarEmail(this.form.email)) {
        this.consultando = true;
        api
          .verificarEmailCadastrado(this.form.email)
          .then((response) => {
            if (response.data) {
              this.erro.emailCadastrado = true;
              message.warning("Esse email já está cadastrado", 5);
            } else {
              this.erro.emailCadastrado = false;
            }

            this.consultando = false;
          })
          .catch((error) => {
            console.log(error);
            this.erro.emailCadastrado = true;
          })
          .finally(() => {
            this.consultando = false;
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tela-cadastro {
  height: 100vh;
  overflow: auto;
  background-color: #163150;
  padding: 20px 8%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (min-width: 680px) and (orientation: portrait) {
  .tela-cadastro {
    padding: 20% 8%;
  }
}

header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-family: "Inter-bold";
  font-size: 16px;
  font-weight: 700;
  line-height: 9.68px;
  text-align: center;
  color: #0194ff;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  .titulo {
    font-family: "Inter";
    font-size: 28px;
    font-weight: 500;
    line-height: 29.14px;
    text-align: left;
    color: #ffffff;
  }
}

.area-formulario {
  width: 100%;
  margin-top: 20px;
}

.formulario {
  width: 100%;

  .area-input {
    margin: 20px 0;

    .erro {
      color: #0194ff;
      font-size: 14px;
      padding: 0 8px;
    }

    .email-cadastrado {
      color: yellow;
      font-size: 14px;
      padding: 0 8px;
    }

    .label {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 500;
      line-height: 12.1px;
      text-align: left;
      color: #ffffff99;
      margin: 8px;
      display: block;
    }

    .input {
      width: 100%;
      background-color: #163150;
      border: 1px solid #ffffff;
      height: 50px;
      border-radius: 4px;
      padding: 0 10px;
      color: #fff;
      font-family: "Inter";
    }

    .area-input-telefone {
      display: flex;
      justify-content: space-between;

      .input-ddi {
        width: 18%;
        background-color: #163150;
        border: 1px solid #ffffff;
        height: 50px;
        border-radius: 4px;
        padding: 0 10px;
        color: #fff;
        font-family: "Inter";
      }

      .input-telefone {
        width: 80%;
        background-color: #163150;
        border: 1px solid #ffffff;
        height: 50px;
        border-radius: 4px;
        padding: 0 10px;
        color: #fff;
        font-family: "Inter";
      }
    }

    .input::placeholder {
      color: #ffffff99;
    }

    .input-ddi::placeholder {
      color: #ffffff99;
    }

    .input-telefone::placeholder {
      color: #ffffff99;
    }
  }
}

footer {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 5%;
  width: 100%;
}

.termos {
  display: inline;
  font-family: "Inter";
  font-size: 10px;
  color: rgba(255, 255, 255, 0.6);
  margin-left: 5px;
}
</style>
