<template>
  <router-view />
</template>

<style lang="less">
@font-face {
  font-family: "Inter";
  src: url("@/assets/fonts/inter.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-bold";
  src: url("@/assets/fonts/Inter_Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-extra-bold";
  src: url("@/assets/fonts/Inter_ExtraBold.ttf") format("truetype");
}

* {
  ::-webkit-scrollbar {
    width: 6px;
    padding: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
</style>
