<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {},

  methods: {},
};
</script>

<style lang="less"></style>
