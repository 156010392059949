import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [createPersistedState()],
  state: {
    cadastroAtual: null,
    respostas: [],
    perfil: null,
    token: null,
  },

  getters: {
    getCadastroAtual: (state) => state.cadastroAtual,
    getRespostas: (state) => state.respostas,
    getPerfil: (state) => state.perfil,
    getToken: (state) => state.token,
  },

  mutations: {
    setCadastroAtual(state, payload) {
      state.cadastroAtual = payload;
    },
    setRespostas(state, payload) {
      state.respostas = payload;
    },
    setPerfil(state, payload) {
      state.perfil = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
  },

  actions: {
    salvarCadastroAtual({ commit }, payload) {
      commit("setCadastroAtual", payload);
    },

    salvarRespostas({ commit }, payload) {
      commit("setRespostas", payload);
    },

    salvarPerfil({ commit }, payload) {
      commit("setPerfil", payload);
    },

    limparCadastroAtual({ commit }) {
      commit("setCadastroAtual", null);
    },

    limparRespostas({ commit }) {
      commit("setRespostas", []);
    },

    limparPerfil({ commit }) {
      commit("setPerfil", null);
    },

    salvarToken({ commit }, payload) {
      commit("setToken", payload);
    },

    limparToken({ commit }) {
      commit("setToken", null);
    },
  },

  modules: {},
});
