<template>
  <img src="@/assets/images/quiz/bg-resultado.png" class="imagem-fundo" />
  <div class="conteudo-tela">
    <section>
      <div class="area-icone-resultado">
        <img
          src="@/assets/images/quiz/icone-resultado.png"
          alt="Ícone resultado"
        />

        <h1 class="seu-perfil">SEU PERFIL</h1>
      </div>

      <div class="janea-resultado" v-if="perfil">
        <div v-if="perfil == 1">
          <div class="titulo-perfil">Apegada às tradições</div>

          <div class="desc-perfil">
            Seu negócio ainda opera com baixo nível de adoção de novas
            tecnologias, com muitas atividades manuais que podem estar gerando
            ineficiência
          </div>
          <div class="desc-perfil">
            Enviamos para o seu e-mail o perfil completo e dicas para sua
            empresa dar os próximos passos nessa jornada de transformação
            digital e de busca por mais eficiência.
          </div>
        </div>

        <div v-if="perfil == 2">
          <div class="titulo-perfil">Exploradora digital</div>

          <div class="desc-perfil">
            A sua empresa está em uma fase de transição, a digitalização já está
            presente em algumas áreas, mas ainda não se consolidou.
          </div>
          <div class="desc-perfil">
            Enviamos para o seu e-mail o perfil completo e dicas para sua
            empresa dar os próximos passos nessa jornada de transformação
            digital e de busca por mais eficiência.
          </div>
        </div>

        <div v-if="perfil == 3">
          <div class="titulo-perfil">Entusiasta digital</div>

          <div class="desc-perfil">
            Parabéns! A sua organização é altamente digitalizada. A automação e
            a integração tecnológica estão em todos os níveis: operacional,
            tático e estratégico.
          </div>

          <div class="desc-perfil">
            Enviamos para o seu e-mail o perfil completo e dicas para sua
            empresa dar os próximos passos nessa jornada de transformação
            digital e de busca por mais eficiência.
          </div>
        </div>
      </div>

      <div class="area-avaliacao">
        <h3>Pronto para o próximo passo?</h3>

        <div>
          <a-tooltip title="Gostei" placement="top">
            <img
              src="@/assets/images/quiz/like.png"
              alt="Gostei"
              class="botao-reacao"
              @click="salvarAvaliacao('like')"
            />
          </a-tooltip>

          <a-tooltip title="Não Gostei" placement="top">
            <img
              src="@/assets/images/quiz/deslike.png"
              alt="Não Gostei"
              class="botao-reacao"
              @click="salvarAvaliacao('deslike')"
            />
          </a-tooltip>
        </div>
      </div>

      <div class="janea-resultado" v-if="!perfil">
        <a-spin
          :spinning="true"
          tip="Aguarde enquanto geramos o seu resultado"
        />
      </div>
    </section>

    <footer>
      <img
        src="@/assets/images/quiz/logo-nome-kurier.png"
        alt="Logo e nome Kurier"
      />
    </footer>
  </div>
</template>

<script>
import BotaoQuiz from "@/components/BotaoQuiz.vue";
import { notification } from "ant-design-vue";
import API from "@/api";
export default {
  components: {
    BotaoQuiz,
  },

  data() {
    return {};
  },

  computed: {
    resultado() {
      return this.$store.getters.getRespostas;
    },

    perfil() {
      return this.$store.getters.getPerfil;
    },

    cadastro() {
      return this.$store.getters.getCadastroAtual;
    },
  },

  mounted() {
    window.history.replaceState(null, null, "/");
  },

  methods: {
    salvarAvaliacao(avalicao) {
      API.salvarAvaliacao(avalicao, this.cadastro.email).then(() => {
        notification.success({
          message: "Avaliação enviada com sucesso!",
          description: "Obrigado por participar",
          duration: 5,
        });
      });

      this.$router.push({ name: "homeQuiz" });
    },
  },
};
</script>

<style lang="less" scoped>
header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-family: "Inter-bold";
  font-size: 10px;
  font-weight: 700;
  line-height: 9.68px;
  text-align: center;
  color: #0194ff;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;

  .janea-resultado {
    width: 100%;
    height: 400px;
    padding: 50px;
    border-radius: 24px;
    background-color: #ffffff;

    border: 2px solid;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-image-source: linear-gradient(
      31.68deg,
      rgba(38, 110, 242, 0.6) 10.11%,
      rgba(119, 167, 255, 0.06) 73.41%
    );
  }

  @media (min-width: 680px) and (orientation: portrait) {
    .janea-resultado {
      height: 600px;
    }
  }

  .area-icone-resultado {
    margin-bottom: -100px;

    z-index: 9;

    img {
      margin-left: 25px;
    }

    .seu-perfil {
      font-family: "Inter-bold";
      font-size: 28px;
      font-weight: 700;
      line-height: 29.05px;
      text-align: center;
      color: #163150;
    }
  }
}

footer {
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.botao-iniciar {
  padding: 8px 30px;
  background-color: #0194ff;
  color: #fff;
  border: #0194ff;
  border-radius: 4px;
  font-family: "Inter-bold";
  cursor: pointer;
  text-align: right;
}

.titulo-perfil {
  font-family: "Inter-bold";
  font-size: 32px;
  font-weight: 700;
  line-height: 29.05px;
  text-align: center;
  color: #163150;
}

.desc-perfil {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: center;
  color: #00000080;
  margin-top: 30px;
}

.area-avaliacao {
  font-family: "Inter-bold";
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
  text-align: center;
  color: #fff;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.botao-reacao {
  margin: 0 20px;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 15px;
  transition: all 0.3s;
  //sombra
  box-shadow: 0px 2px 2px rgba(255, 255, 255, 0.25);
}

.botao-reacao:hover {
  transform: scale(1.1);
  background-color: #0194ff;
  opacity: 0.8;
}
</style>
