export default ({ instance }) => {
  return {
    cadastroRespostaQuiz(data) {
      return instance({
        method: "post",
        url: "quiz/cadastro-quiz",
        data: data,
      });
    },

    verificarEmailCadastrado(data) {
      return instance({
        method: "get",
        url: "quiz/verificar-email/" + data,
      });
    },

    salvarAvaliacao(avaliacao, email) {
      return instance({
        method: "post",
        url: "quiz/avaliar/" + email + "/" + avaliacao,
      });
    },
  };
};
