<template>
  <div>
    <a-row type="flex" justify="center" align="middle" class="card-grande">
      <a-col :span="24">
        <a-form layout="vertical">
          <a-row type="flex" justify="start" align="middle" :gutter="30">
            <a-col :span="8">
              <a-form-item label="CNPJ">
                <a-input
                  v-model:value="cnpj"
                  type="text"
                  v-mask="'##.###.###/####-##'"
                  placeholder="Digite o CNPJ"
                  @pressEnter="
                    empresa &&
                    empresa != 'Empresa não encontrada' &&
                    cnpj.length == 18
                      ? realizarConsultas()
                      : false
                  "
                />
              </a-form-item>
              <span class="erro-consultar-cnpj" v-if="erroConsulta">
                Não foi possível consultar os dados deste CNPJ
              </span>
            </a-col>

            <a-col :span="2">
              <a-form-item label=" ">
                <a-button
                  type="primary"
                  :disabled="!empresa || empresa == 'Empresa não encontrada'"
                  @click="realizarConsultas()"
                  :loading="loading"
                >
                  <SearchOutlined />
                </a-button>
              </a-form-item>
            </a-col>

            <a-col :span="14" v-if="empresa && cnpj.length == 18">
              <span class="nome-empresa">
                {{ empresa }}
              </span>
            </a-col>
          </a-row>
        </a-form>
      </a-col>
    </a-row>

    <a-row type="flex" justify="space-between" align="middle">
      <ResultadosPerfilJuridico
        v-if="periodoSelecionado == 'sempre' && result.processosEver"
        :result="result.processosEver"
        :resultGeral="result"
      >
        <a-form layout="horizontal" style="width: 100%">
          <a-form-item label="Período">
            <a-select
              v-model:value="periodoSelecionado"
              placeholder="Selecione um tipo de polo"
              style="width: 100%"
            >
              <a-select-option value="sempre" :disabled="!result.processosEver">
                Últimos 15 Anos
              </a-select-option>

              <a-select-option value="2anos" :disabled="!result.processos2Anos">
                Últimos 2 Anos
                <span v-if="!result.processos2Anos">
                  (Não foram encontrados processos neste período)
                </span>
              </a-select-option>

              <a-select-option
                value="6meses"
                :disabled="!result.processos6Meses"
              >
                Últimos 6 Meses
                <span v-if="!result.processos6Meses">
                  (Não foram encontrados processos neste período)
                </span>
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </ResultadosPerfilJuridico>

      <ResultadosPerfilJuridico
        v-if="periodoSelecionado == '2anos' && result.processos2Anos"
        :result="result.processos2Anos"
        :resultGeral="result"
      >
        <a-form layout="horizontal" style="width: 100%">
          <a-form-item label="Período">
            <a-select
              v-model:value="periodoSelecionado"
              placeholder="Selecione um tipo de polo"
              style="width: 100%"
            >
              <a-select-option value="sempre" :disabled="!result.processosEver">
                Últimos 15 Anos
              </a-select-option>

              <a-select-option value="2anos" :disabled="!result.processos2Anos">
                Últimos 2 Anos
                <span v-if="!result.processos2Anos">
                  (Não foram encontrados processos neste período)
                </span>
              </a-select-option>

              <a-select-option
                value="6meses"
                :disabled="!result.processos6Meses"
              >
                Últimos 6 Meses
                <span v-if="!result.processos6Meses">
                  (Não foram encontrados processos neste período)
                </span>
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </ResultadosPerfilJuridico>

      <ResultadosPerfilJuridico
        v-if="periodoSelecionado == '6meses' && result.processos6Meses"
        :result="result.processos6Meses"
        :resultGeral="result"
      >
        <a-form layout="horizontal" style="width: 100%">
          <a-form-item label="Período">
            <a-select
              v-model:value="periodoSelecionado"
              placeholder="Selecione um tipo de polo"
              style="width: 100%"
            >
              <a-select-option value="sempre" :disabled="!result.processosEver">
                Últimos 15 Anos
              </a-select-option>

              <a-select-option value="2anos" :disabled="!result.processos2Anos">
                Últimos 2 Anos
                <span v-if="!result.processos2Anos">
                  (Não foram encontrados processos neste período)
                </span>
              </a-select-option>

              <a-select-option
                value="6meses"
                :disabled="!result.processos6Meses"
              >
                Últimos 6 Meses
                <span v-if="!result.processos6Meses">
                  (Não foram encontrados processos neste período)
                </span>
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </ResultadosPerfilJuridico>
    </a-row>

    <div class="area-loading" v-if="loading">
      <LoadingPerfilJuridico />
    </div>

    <div
      class="area-loading"
      v-if="
        !result.processosEver &&
        !result.processos2Anos &&
        !result.processos6Meses &&
        !loading &&
        inicio
      "
    >
      <h1 class="descricao-perfil-juridico">
        Conheça o Perfil Jurídico de uma Empresa
      </h1>
      <h2 class="descricao-perfil-juridico">Comece informando o CNPJ</h2>
    </div>

    <div
      class="area-loading"
      v-if="
        !result.processosEver &&
        !result.processos2Anos &&
        !result.processos6Meses &&
        !loading &&
        !inicio
      "
    >
      <h2 class="descricao-perfil-juridico">
        Não foram encontrados processos para este CNPJ.
      </h2>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import {
  CaretUpOutlined,
  ClockCircleFilled,
  SearchOutlined,
} from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import LoadingPerfilJuridico from "@/components/LoadingPerfilJuridico.vue";
import ResultadosPerfilJuridico from "@/components/ResultadosPerfilJuridico.vue";

export default {
  components: {
    CaretUpOutlined,
    ClockCircleFilled,
    SearchOutlined,
    LoadingPerfilJuridico,
    ResultadosPerfilJuridico,
  },

  data() {
    return {
      erroConsulta: false,
      inicio: true,
      periodoSelecionado: "sempre",
      cnpj: "",
      empresa: "",
      loading: false,
      loadingEmpresa: false,
      result: {
        processosEver: null,
        processos2Anos: null,
        processos6Meses: null,
      },
    };
  },

  watch: {
    cnpj(value) {
      this.erroConsulta = false;
      if (value.length == 18) {
        this.consultarEmpresaPorCnpj(this.removerPontuacao(value));
      } else {
        this.empresa = "";
      }
    },
  },

  created() {},

  mounted() {
    window.history.replaceState(null, null, "/");
  },

  methods: {
    removerPontuacao(value) {
      return value.replace(/[^0-9]/g, "");
    },

    consultarEmpresaPorCnpj(value) {
      this.loadingEmpresa = true;
      this.empresa = "";

      API.consultarEmpresaPorCnpj(value)

        .then((response) => {
          if (!response.data) {
            this.empresa = "Empresa não encontrada";
          } else {
            this.empresa = response.data;
          }
        })
        .catch((error) => {
          message.error("Erro ao consultar dados");
        })
        .finally(() => {
          this.loadingEmpresa = false;
        });
    },

    realizarConsultas() {
      this.erroConsulta = false;
      this.loading = true;
      this.result = {
        processosEver: null,
        processos2Anos: null,
        processos6Meses: null,
      };
      this.inicio = false;

      API.consultarApiRisco(this.empresa, this.removerPontuacao(this.cnpj))
        .then((response) => {
          this.result = response.data;
        })
        .catch((error) => {
          this.inicio = true;
          this.erroConsulta = true;
          message.error("Não foi possível consultar os dados");
          this.result = {
            processosEver: null,
            processos2Anos: null,
            processos6Meses: null,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },

    formatarMilharBr(valor) {
      return valor.toLocaleString("pt-BR");
    },

    formatarPalavra(valor) {
      if (valor == "REU") {
        return "Réu";
      } else if (valor == "AUTOR") {
        return "Autor";
      } else {
        return valor;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.card-grande {
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 #00000014;
  padding: 10px 5%;
}

.nome-empresa {
  font-size: 18px;
  line-height: 29.05px;
  text-align: left;
  color: #0302294d;
  font-family: "Inter-bold";
}

.area-loading {
  height: calc(90vh - 350px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h1.descricao-perfil-juridico {
  font-size: 32px;
  font-weight: 500;
  line-height: 29.05px;
  text-align: center;
  color: #0302294d;
  font-family: "Inter-bold";
}

h2.descricao-perfil-juridico {
  font-size: 28px;
  font-weight: 500;
  line-height: 29.05px;
  text-align: center;
  color: #0302294d;
  font-family: "Inter-bold";
}

.erro-consultar-cnpj {
  display: block;
  margin-top: -22px;
  color: red;
}
</style>
