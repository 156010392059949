export default ({ instance }) => {
  return {
    qtdTodosProcessos() {
      return instance({
        method: "get",
        url: "distribuicao/todos-processos-kurier",
      });
    },

    qtdPublicacoesFenalaw() {
      return instance({
        method: "get",
        url: "distribuicao/publicacoes-fenalaw",
      });
    },

    qtdAndamentosFenalaw() {
      return instance({
        method: "get",
        url: "distribuicao/andamentos-fenalaw",
      });
    },

    qtdProcessosFenalaw() {
      return instance({
        method: "get",
        url: "distribuicao/processos-fenalaw",
      });
    },

    amostraProcessos() {
      return instance({
        method: "get",
        url: "distribuicao/amostra-processos",
      });
    },

    qtdProcessos() {
      return instance({
        method: "get",
        url: "distribuicao/quantidade-processos",
      });
    },

    preditivoAnalytics(npu) {
      return instance({
        method: "get",
        url: "analytics/" + npu,
      });
    },

    quantidadeTotalOpensearch() {
      return instance({
        method: "get",
        url: "opensearch/quantidade-total",
      });
    },

    consultarMateria(justica) {
      return instance({
        method: "get",
        url: "opensearch/consultar-materia/" + justica,
      });
    },

    consultarApiRisco(nome, cnpj) {
      return instance({
        method: "get",
        url: "RiscoFraude/" + nome + "/" + cnpj,
      });
    },

    consultarEmpresaPorCnpj(cnpj) {
      return instance({
        method: "get",
        url: "RiscoFraude/empresa/" + cnpj,
      });
    },

    consultarQtdUfPorJustica(justica) {
      return instance({
        method: "get",
        url: "distribuicao/qtd-uf/" + justica,
      });
    },
  };
};
