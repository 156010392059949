<template>
  <div id="quiz">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    this.irParaHome();
  },

  methods: {
    irParaHome() {
      this.$store.dispatch("limparCadastroAtual");
      this.$store.dispatch("limparRespostas");
      this.$router.push({ name: "homeQuiz" });
    },
  },
};
</script>

<style lang="less">
.imagem-fundo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.conteudo-tela {
  height: 100vh;
  padding: 20px 8%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

@media (min-width: 680px) and (orientation: portrait) {
  .conteudo-tela {
    padding: 20% 8%;
  }
}
</style>
