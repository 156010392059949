<template>
  <Pie :data="data" :options="options" :width="width" :height="height" />
</template>

<script>
import { Pie } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  ChartDataLabels
);

export default {
  name: "BarChart",
  components: { Pie },
  props: {
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 300,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 90,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (a) {
                return a.parsed + "%";
              },
            },
          },
          datalabels: {
            color: "#fff",
            font: {
              size: 16,
              weight: "bold",
            },
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex];
            },
          },
        },
      },
    };
  },
};
</script>
