<template>
  <img src="@/assets/images/quiz/bg-perguntas.png" class="imagem-fundo" />
  <div class="conteudo-tela">
    <header>
      <div>DESCUBRA O GRAU DE MATURIDADE DIGITAL DA SUA EMPRESA</div>
    </header>

    <section>
      <h1 class="titulo">
        <!-- <span class="categoria">
          <img
            src="@/assets/images/quiz/icone-automacao.png"
            v-if="perguntas[atual].icone === 'icone-automacao'"
          />
          <img
            src="@/assets/images/quiz/icone-sistemas-integrados.png"
            v-if="perguntas[atual].icone === 'icone-sistemas-integrados'"
          />
          <img
            src="@/assets/images/quiz/icone-tomada-decisao.png"
            v-if="perguntas[atual].icone === 'icone-tomada-decisao'"
          />
          <img
            src="@/assets/images/quiz/icone-uso-ia.png"
            v-if="perguntas[atual].icone === 'icone-uso-ia'"
          />

          {{ perguntas[atual].categoria }}
        </span> -->
        {{ perguntas[atual].titulo }}
        <div v-if="atual == 1" style="font-size: 16px; margin-top: 10px">
          (múltipla seleção)
        </div>
      </h1>

      <Alternativas
        v-model="opcaoSelecionada"
        :alternativas="perguntas[atual].alternativas"
      />
    </section>

    <footer>
      <BotaoQuiz @click="proximaPergunta()" v-if="respostas.length < 4">
        Próximo!
      </BotaoQuiz>

      <BotaoQuiz @click="finalizarQuiz()" v-if="respostas.length == 4">
        Finalizar
      </BotaoQuiz>
    </footer>
  </div>
</template>

<script>
import BotaoQuiz from "@/components/BotaoQuiz.vue";
import Alternativas from "@/components/AlternativasQuiz.vue";
import { notification } from "ant-design-vue";
import api from "@/api";

export default {
  components: {
    BotaoQuiz,
    Alternativas,
  },

  data() {
    return {
      opcaoSelecionada: null,
      atual: 0,
      respostas: [],
      perguntas: [
        {
          id: 1,
          titulo:
            "Se você pudesse quantificar o quanto da rotina da operação do seu Escritório ou Departamento Jurídico é automatizada, você indicaria:",
          categoria: "Automação",
          icone: "icone-automacao",
          alternativas: [
            { titulo: "Acima de 80%", valor: "Muito Alto" },
            { titulo: "Entre 51% e 80%", valor: "Alto" },
            { titulo: "Entre 30% e 50%", valor: "Mediano" },
            { titulo: "Entre 11% e 29%", valor: "Baixo" },
            { titulo: "Até 10%", valor: "Muito Baixo" },
          ],
        },
        {
          id: 2,
          titulo:
            " Se você utiliza um sistema de gestão no seu Escritório ou Departamento Jurídico indique quais soluções digitais abaixo estão integradas a ele:",
          categoria: "Sistemas integrados ",
          icone: "icone-sistemas-integrados",
          alternativas: [
            { titulo: "Novas Publicações", valor: "1" },
            { titulo: "Assinatura digital", valor: "2" },
            { titulo: "Geração de peças automáticas", valor: "3" },
            { titulo: "Peticionamento eletrônico", valor: "4" },
            { titulo: "Intimações/citações eletrônicas", valor: "5" },
            { titulo: "Novos Processos", valor: "6" },
            { titulo: "Jurimetria", valor: "7" },
            { titulo: "Análise Preditiva", valor: "8" },
            { titulo: "Não tenho sistema de gestão", valor: "9" },
          ],
        },
        {
          id: 3,
          titulo:
            "Dessas ferramentas digitais que você utiliza integradas ao sistema de gestão do seu Escritório ou Departamento Jurídico, quantas possuem recursos de IA - Inteligência Artificial?",
          categoria: "Tomada de decisão",
          icone: "icone-uso-ia",
          alternativas: [
            { titulo: "7 a 8", valor: "Muito Alto" },
            { titulo: "5 a 6", valor: "Alto" },
            { titulo: "3 a 4", valor: "Mediano" },
            { titulo: "1 a 2", valor: "Baixo" },
            { titulo: "Não uso sistema de gestão", valor: "Muito Baixo" },
          ],
        },
        {
          id: 4,
          titulo:
            "Considerando o período de 1 mês, no dia a dia do seu Escritório ou Departamento Jurídico com que frequência as decisões são tomadas baseadas em dados?",
          categoria: "Uso de Inteligência Artificial",
          icone: "icone-tomada-decisao",
          alternativas: [
            { titulo: "Diariamente ", valor: "Muito Alto" },
            { titulo: "2 vezes por semana", valor: "Alto" },
            { titulo: "Quinzenalmente ", valor: "Mediano" },
            { titulo: "1 vez ao mês", valor: "Baixo" },
            { titulo: "Pouco frequente", valor: "Muito Baixo" },
          ],
        },
        {
          id: 5,
          titulo:
            "Qual a possibilidade da sua empresa investir numa possível jornada de transformação com o uso de tecnologias?",
          categoria: "Cultura Digital",
          icone: "icone-uso-ia",
          alternativas: [
            { titulo: "Muito Alta ", valor: "Muito Alto" },
            { titulo: "Alta", valor: "Alto" },
            { titulo: "Mediana", valor: "Mediano" },
            { titulo: "Baixa", valor: "Baixo" },
            { titulo: "Muito Baixa", valor: "Muito Baixo" },
          ],
        },
      ],
    };
  },

  mounted() {
    window.history.replaceState(null, null, "/");
  },

  computed: {
    cadastroAtual() {
      return this.$store.getters.getCadastroAtual;
    },
  },

  methods: {
    proximaPergunta() {
      if (!this.opcaoSelecionada) {
        notification.warning({
          message: "Você precisa selecionar uma opção!",
          placement: "top",
          duration: 3,
          closeIcon: false,
        });

        return;
      } else {
        this.respostas.push({
          id: this.perguntas[this.atual].id,
          pergunta: this.perguntas[this.atual].titulo,
          resposta: this.opcaoSelecionada,
        });
        this.opcaoSelecionada = null;
        this.atual++;
      }
    },

    finalizarQuiz() {
      this.respostas.push({
        id: this.perguntas[this.atual].id,
        pergunta: this.perguntas[this.atual].titulo,
        resposta: this.opcaoSelecionada,
      });

      this.$store.dispatch("salvarRespostas", this.respostas);

      let listaRespostas = this.respostas.map((e) => e.resposta);
      let data = {
        nome: this.cadastroAtual.nome,
        email: this.cadastroAtual.email,
        telefone: this.cadastroAtual.telefone,
        empresa: this.cadastroAtual.empresa,
        cargo: this.cadastroAtual.cargo,
        numeroFuncionarios: this.cadastroAtual.numeroFuncionarios,
        resposta1: listaRespostas[0],
        resposta2: listaRespostas[1],
        resposta3: listaRespostas[2],
        resposta4: listaRespostas[3],
        resposta5: listaRespostas[4],
      };
      api
        .cadastroRespostaQuiz(data)
        .then((response) => {
          this.$store.dispatch("salvarPerfil", response.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$router.push({ name: "resultadoQuiz" });
        });
    },
  },
};
</script>

<style lang="less" scoped>
header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-family: "Inter-bold";
  font-size: 16px;
  font-weight: 700;
  line-height: 9.68px;
  text-align: center;
  color: #0194ff;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

footer {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 5%;
  width: 100%;
}

.titulo {
  font-family: "Inter";
  font-size: 26px;
  font-weight: 500;
  line-height: 24.2px;
  text-align: center;
  color: #ffffff;
  width: 70%;
  margin-bottom: 50px;

  .categoria {
    display: block;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-family: Inter;
    font-size: 20px;
    line-height: 19.36px;
    text-align: center;
  }
}
</style>
