<template>
  <a-row type="flex" justify="center" align="middle" class="area-menu">
    <a-col :xs="23" :sm="22" :md="22" :lg="20" :xl="20" :xxl="16">
      <a-row type="flex" justify="space-between" align="middle">
        <a-col :span="6" class="imagem-logo-menu">
          <router-link :to="{ name: 'nossosNumeros' }">
            <img
              src="@/assets/images/logo-kurier.png"
              alt="logo-kurier"
              class="logo"
            />
          </router-link>
        </a-col>

        <a-col
          :span="3"
          :class="
            selecionado('nossosNumeros')
              ? 'area-item-menu-selecionado'
              : 'area-item-menu'
          "
        >
          <router-link
            :to="{ name: 'nossosNumeros' }"
            :class="
              selecionado('nossosNumeros')
                ? 'item-menu-selecionado'
                : 'item-menu'
            "
          >
            Processômetro
          </router-link>
        </a-col>

        <a-col
          :span="5"
          :class="
            selecionado('distribuicoesMateria')
              ? 'area-item-menu-selecionado'
              : 'area-item-menu'
          "
        >
          <router-link
            :to="{ name: 'distribuicoesMateria' }"
            :class="
              selecionado('distribuicoesMateria')
                ? 'item-menu-selecionado'
                : 'item-menu'
            "
          >
            Processos por Matéria
          </router-link>
        </a-col>

        <a-col
          :span="3"
          :class="
            selecionado('perfilJuridico')
              ? 'area-item-menu-selecionado'
              : 'area-item-menu'
          "
        >
          <router-link
            :to="{ name: 'perfilJuridico' }"
            :class="
              selecionado('perfilJuridico')
                ? 'item-menu-selecionado'
                : 'item-menu'
            "
          >
            Perfil Jurídico
          </router-link>
        </a-col>

        <a-col
          :span="4"
          :class="
            selecionado('analisePreditiva')
              ? 'area-item-menu-selecionado'
              : 'area-item-menu'
          "
        >
          <router-link
            :to="{ name: 'analisePreditiva' }"
            :class="
              selecionado('analisePreditiva')
                ? 'item-menu-selecionado'
                : 'item-menu'
            "
          >
            Análise Preditiva
          </router-link>
        </a-col>
      </a-row>
    </a-col>
  </a-row>

  <div class="conteudo">
    <a-row type="flex" justify="center" align="middle">
      <a-col :xs="23" :sm="22" :md="22" :lg="20" :xl="20" :xxl="16">
        <router-view />
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  created() {
    //this.$router.push({ name: "homeQuiz" });
  },

  computed: {
    nameRota() {
      return this.$route.name;
    },
  },

  methods: {
    selecionado(rota) {
      return this.nameRota == rota;
    },
  },
};
</script>

<style lang="less" scoped>
.area-menu {
  height: 120px;
  background-color: #1b1d27;
}

.imagem-logo-menu {
  text-align: left;
}

.bota-novo-processos {
  text-align: right;
}

.item-menu {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #fff;
  font-family: "Inter-bold";
  user-select: none;
}

.area-item-menu {
  text-align: center;
  padding: 5px;
}

.area-item-menu-selecionado {
  text-align: center;
  background-color: #266ef2;
  border-radius: 4px;
  padding: 5px;
}

.item-menu-selecionado {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #fff;
  font-family: "Inter-bold";
  user-select: none;
}

.botao-novos-processos {
  font-size: 14px;
  font-weight: 600;
  height: 48px;
  width: 229px;
  letter-spacing: -0.015em;
  text-align: left;
  background-color: #266ef2;
  border: 1px solid #266ef2;
  color: #fff;
  text-align: center;
  font-family: "Inter-bold";
}

.botao-novos-processos:hover {
  background-color: #266df2ca;
  border: 1px solid #266df2ca;
  color: #fff;
}

.conteudo {
  background-color: #fafafb;
  height: calc(100vh - 120px);
  padding-top: 30px;
  overflow: auto;
  padding-bottom: 30px;
}

.logo {
  user-select: none;
}
</style>
