<template>
  <img src="@/assets/images/quiz/bg-home.png" class="imagem-fundo" />
  <div class="conteudo-tela">
    <header>
      <div>
        <img
          src="@/assets/images/quiz/logo-nome-kurier.png"
          alt="Logo e nome Kurier"
        />
      </div>
    </header>

    <section>
      <h1 class="titulo">
        Descubra o grau de maturidade digital da sua empresa
      </h1>

      <h2 class="subtitulo">
        Embarque na jornada de transformação digital jurídica com a Kurier.
      </h2>
    </section>

    <footer>
      <BotaoQuiz @click="comecar()">Vamos lá</BotaoQuiz>
    </footer>
  </div>
</template>

<script>
import BotaoQuiz from "@/components/BotaoQuiz.vue";
export default {
  components: {
    BotaoQuiz,
  },

  mounted() {
    window.history.replaceState(null, null, "/");
  },

  methods: {
    comecar() {
      this.$router.push({ name: "apresentacaoQuiz" });
    },
  },
};
</script>

<style lang="less" scoped>
header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}

section {
  height: calc(100vh - 250px - 250px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  .titulo {
    width: 90%;
    font-family: "Inter-bold";
    font-size: 48px;
    font-weight: 700;
    line-height: 58.09px;
    text-align: left;
    color: #ffffff;
  }

  .subtitulo {
    width: 90%;
    font-family: "Inter";
    font-size: 24px;
    font-weight: 500;
    line-height: 29.05px;
    text-align: left;
    color: #ffffff;
  }
}

footer {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 50px;
  width: 100%;
}
</style>
