<template>
  <img src="@/assets/images/quiz/bg-perguntas.png" class="imagem-fundo" />

  <div class="conteudo-tela">
    <header>DESCUBRA O GRAU DE MATURIDADE DIGITAL DA SUA EMPRESA</header>

    <section>
      <h1 class="titulo">
        Cada vez mais escritórios de advocacia e departamentos jurídicos
        entendem a necessidade de incorporar tecnologias à sua rotina. Das
        automações à Inteligência Artificial, o desejo pela transformação
        digital com ferramentas que otimizem o dia a dia só cresce. Responda 5
        perguntas para conhecer o cenário de maturidade digital jurídica da sua
        empresa.
      </h1>

      <div class="area-botao">
        <BotaoQuiz @click="comecar()">Vamos começar!</BotaoQuiz>
      </div>
    </section>

    <div style="height: 100px"></div>
  </div>
</template>

<script>
import BotaoQuiz from "@/components/BotaoQuiz.vue";
export default {
  components: {
    BotaoQuiz,
  },

  mounted() {
    window.history.replaceState(null, null, "/");
  },

  methods: {
    comecar() {
      this.$router.push({ name: "cadastroQuiz" });
    },
  },
};
</script>

<style lang="less" scoped>
header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-family: "Inter-bold";
  font-size: 16px;
  font-weight: 700;
  line-height: 9.68px;
  text-align: center;
  color: #0194ff;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (min-width: 680px) and (orientation: portrait) {
  .titulo {
    width: 80%;
  }
}

.titulo {
  font-family: "Inter";
  font-size: 28px;
  font-weight: 500;
  line-height: 29.14px;
  text-align: center;
  color: #ffffff;
}

.area-botao {
  width: 50%;

  margin-top: 40px;
}
</style>
