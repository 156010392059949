<template>
  <a-row type="flex" justify="center" align="middle" :gutter="20">
    <a-col :span="24">
      <div class="area-materias">
        <div class="header-materias">
          <a-row type="flex" justify="space-between" align="middle">
            <a-col :span="10">
              <div class="titulo">
                Matéria Principal <a-tag class="top-10">Top 10</a-tag>
              </div>
            </a-col>

            <a-col :class="14">
              <a-radio-group
                v-model:value="selecionado"
                button-style="solid"
                class="radio"
                size="large"
              >
                <a-radio-button value="federal">
                  Justiça Federal
                </a-radio-button>
                <a-radio-button value="estadual">
                  Justiça Estadual
                </a-radio-button>
                <a-radio-button value="trabalho">
                  Justiça do Trabalho
                </a-radio-button>
              </a-radio-group>
            </a-col>
          </a-row>
        </div>

        <div class="conteudo" v-if="result[selecionado].length > 0">
          <template v-for="valor in result[selecionado]" :key="valor.nome">
            <a-row
              type="flex"
              justify="center"
              align="middle"
              class="item-materia"
            >
              <a-col :span="11">
                <div class="texto">{{ valor.materia }}</div>
              </a-col>

              <a-col :span="8">
                <a-progress
                  :percent="percentual(valor.quantidade, selecionado)"
                  :size="[10, 12]"
                  style="width: 110%; margin-top: 5px"
                />
              </a-col>

              <a-col :span="2">
                <div class="quantidade">
                  {{ formatarMilharBr(valor.quantidade) }}
                </div>
              </a-col>
            </a-row>
          </template>
        </div>

        <div class="conteudo" v-else>
          <a-row
            type="flex"
            justify="center"
            align="middle"
            class="item-materia-skeleton"
            v-for="i in 10"
            :key="i"
          >
            <a-col :span="21">
              <a-skeleton
                active
                :title="false"
                :paragraph="{ rows: 1 }"
                style="width: 165%"
              />
            </a-col>
          </a-row>
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import API from "@/api";

export default {
  data() {
    return {
      selecionado: "federal",
      total: [],
      result: {
        federal: [],
        estadual: [],
        trabalho: [],
      },
    };
  },

  mounted() {
    window.history.replaceState(null, null, "/");
    this.consultarQuantidade();
    this.consultarMaterias("federal");
    this.consultarMaterias("estadual");
    this.consultarMaterias("trabalho");
  },

  methods: {
    percentual(a, justica) {
      if (this.total.length === 0) return 0;

      let qtd = this.total.find(
        (item) => item.justica === this.nomeJusticaConsulta(justica)
      ).quantidade;

      return (a / qtd) * 100;
    },

    consultarQuantidade() {
      API.quantidadeTotalOpensearch().then((response) => {
        this.total = response.data;
      });
    },

    consultarMaterias(justica) {
      API.consultarMateria(this.nomeJusticaConsulta(justica)).then(
        (response) => {
          this.result[justica] = response.data.slice(0, 10);
        }
      );
    },

    formatarMilharBr(valor) {
      return valor.toLocaleString("pt-BR");
    },

    nomeJusticaConsulta(justica) {
      switch (justica) {
        case "federal":
          return "JUSTICA FEDERAL";
        case "estadual":
          return "JUSTICA ESTADUAL";
        case "trabalho":
          return "JUSTICA DO TRABALHO";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.area-materias {
  width: 100%;
  height: 627px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 #00000014;
  padding: 40px;
  background-color: #fff;
}

.header-materias {
  .titulo {
    font-family: "Inter-bold";
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    color: #03022999;
    display: flex;
    align-items: center;
  }

  .radio {
    font-family: "Inter-bold";
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: center;
    color: #ffffff;
  }

  .top-10 {
    border: none;
    border-radius: 30px;
    background-color: #266ef20d;
    color: #266ef2;
    font-family: "Inter-bold";
    padding: 3px 10px;
    margin-left: 20px;
  }
}

.conteudo {
  margin-top: 50px;
  width: 100%;

  .texto {
    font-family: "Inter-bold";
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #03022999;
  }

  .quantidade {
    font-family: "Inter-bold";
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-align: right;
    color: #03022999;
  }

  .item-materia {
    margin-bottom: 10px;
  }

  .item-materia-skeleton {
    margin: 10px 0 16px 0;
  }
}

:deep(.ant-radio-button-wrapper) {
  color: #0302294d;
  font-size: 14px;
  padding: 0 30px;
  height: 48px;
  line-height: 48px;
}

:deep(.ant-radio-button-wrapper:hover) {
  color: #030229a5;
}

:deep(.ant-progress .ant-progress-text) {
  display: none;
}
</style>
