import axios from "axios";
import store from "@/store";
import portal from "./portal";
import quizz from "./quizz";
import admin from "./admin";

const instance = axios.create({
  baseURL: process.env.VUE_APP_FENALAW_API,
});

// instance.defaults.headers.post["Content-Type"] = "json";
// instance.defaults.headers.put["Content-Type"] = "json";
instance.defaults.responseType = "json";
instance.interceptors.request.use((config) => {
  if (store.getters.getToken)
    config.headers["Authorization"] = `Bearer ${store.getters.getToken}`;

  return config;
});

export default {
  ...portal({ instance }),
  ...quizz({ instance }),
  ...admin({ instance }),
};
