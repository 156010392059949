<template>
  <div style="width: 100%">
    <a-row type="flex" justify="space-between" align="middle" :gutter="20">
      <a-col :span="8">
        <a-row
          type="flex"
          justify="space-between"
          align="middle"
          class="filtro"
        >
          <a-col :span="24">
            <slot> </slot>
          </a-col>
        </a-row>
        <a-row
          type="flex"
          justify="start"
          align="middle"
          :gutter="10"
          v-if="result.polos.length"
        >
          <a-col :span="12">
            <div
              :class="
                tipoPoloSelecionado == 'REU'
                  ? 'card-pequeno-botao-selecionado'
                  : result.polos.find((p) => p.nome == 'REU')?.qtd
                  ? 'card-pequeno-botao'
                  : 'card-pequeno-botao-desabilitado'
              "
              @click="selecionarTipoPolo('REU')"
            >
              <div class="titulo-card-pequeno">Réu</div>
              <div class="valor-card-pequeno">
                {{
                  formatarMilharBr(
                    result.polos.find((p) => p.nome == "REU")?.qtd || 0
                  )
                }}
              </div>
            </div>
          </a-col>

          <a-col :span="12">
            <div
              :class="
                tipoPoloSelecionado == 'AUTOR'
                  ? 'card-pequeno-botao-selecionado'
                  : result.polos.find((p) => p.nome == 'AUTOR')?.qtd
                  ? 'card-pequeno-botao'
                  : 'card-pequeno-botao-desabilitado'
              "
              @click="selecionarTipoPolo('AUTOR')"
            >
              <div class="titulo-card-pequeno">Autor</div>
              <div class="valor-card-pequeno">
                {{
                  formatarMilharBr(
                    result.polos.find((p) => p.nome == "AUTOR")?.qtd || 0
                  )
                }}
              </div>
            </div>
          </a-col>
        </a-row>

        <a-row type="flex" justify="start" align="middle" :gutter="10" v-else>
          <a-col :span="8">
            <a-row type="flex" justify="start" align="middle" :gutter="10">
              <a-col :span="24">
                <slot> </slot>
              </a-col>
            </a-row>

            <div
              :class="
                tipoPoloSelecionado == 'REU'
                  ? 'card-pequeno-botao-selecionado'
                  : 'card-pequeno-botao'
              "
              @click="selecionarTipoPolo(polo.nome)"
            >
              <div class="titulo-card-pequeno">Réu</div>
              <div class="valor-card-pequeno">0</div>
            </div>
          </a-col>

          <a-col :span="12">
            <div
              :class="
                tipoPoloSelecionado == 'AUTOR'
                  ? 'card-pequeno-botao-selecionado'
                  : 'card-pequeno-botao'
              "
              @click="selecionarTipoPolo(polo.nome)"
            >
              <div class="titulo-card-pequeno">Autor</div>
              <div class="valor-card-pequeno">0</div>
            </div>
          </a-col>
        </a-row>
      </a-col>

      <a-col :span="8">
        <div class="card-pequeno">
          <div class="titulo-card-medio">
            Período
            <span v-if="tipoPoloSelecionado">
              ({{ formatarPalavra(tipoPoloSelecionado) }})
            </span>
          </div>
          <div v-if="result.tipos.length > 0">
            <Barra
              :data="dataPeriodos.reu"
              :options="optionsPeriodo"
              :height="100"
              :width="400"
              v-if="tipoPoloSelecionado == 'REU'"
            />

            <Barra
              :data="dataPeriodos.autor"
              :options="optionsPeriodo"
              :height="100"
              :width="400"
              v-if="tipoPoloSelecionado == 'AUTOR'"
            />

            <Barra
              :data="dataPeriodos.ambos"
              :options="optionsPeriodo"
              :height="100"
              :width="400"
              v-if="tipoPoloSelecionado == ''"
            />
          </div>
          <div v-else>
            <a-empty :description="null" />
          </div>
        </div>
      </a-col>

      <a-col :span="8">
        <div class="card-pequeno">
          <div class="titulo-card-medio">
            Área
            <span v-if="tipoPoloSelecionado">
              ({{ formatarPalavra(tipoPoloSelecionado) }})
            </span>
          </div>
          <div v-if="result.tipos.length > 0">
            <Barra
              :data="dataTipos.reu"
              :options="optionsBarra"
              :height="100"
              :width="380"
              v-if="tipoPoloSelecionado == 'REU'"
            />

            <Barra
              :data="dataTipos.autor"
              :options="optionsBarra"
              :height="100"
              :width="380"
              v-if="tipoPoloSelecionado == 'AUTOR'"
            />

            <Barra
              :data="dataTipos.ambos"
              :options="optionsBarra"
              :height="100"
              :width="380"
              v-if="tipoPoloSelecionado == ''"
            />
          </div>
          <div v-else>
            <a-empty :description="null" />
          </div>
        </div>
      </a-col>
    </a-row>

    <a-row type="flex" justify="space-between" align="middle" :gutter="20">
      <a-col :span="12">
        <div class="card-medio">
          <div class="titulo-card-medio">
            UF

            <span v-if="tipoPoloSelecionado">
              ({{ formatarPalavra(tipoPoloSelecionado) }})
            </span>
          </div>
          <div v-if="result.ufs.length > 0">
            <Pizza
              :data="dataUfs.reu"
              :options="optionsPizza"
              :height="250"
              :width="600"
              v-if="tipoPoloSelecionado == 'REU'"
            />
            <Pizza
              :data="dataUfs.autor"
              :options="optionsPizza"
              :height="250"
              :width="600"
              v-if="tipoPoloSelecionado == 'AUTOR'"
            />
            <Pizza
              :data="dataUfs.ambos"
              :options="optionsPizza"
              :height="250"
              :width="600"
              v-if="tipoPoloSelecionado == ''"
            />
          </div>
          <div v-else>
            <a-empty :description="null" />
          </div>
        </div>
      </a-col>

      <a-col :span="12">
        <div class="card-medio">
          <div class="titulo-card-medio">
            Classe
            <span v-if="tipoPoloSelecionado">
              ({{ formatarPalavra(tipoPoloSelecionado) }})
            </span>
          </div>
          <div v-if="result.classes.length > 0">
            <Circulo
              :data="dataClasses.reu"
              :options="options2"
              :height="250"
              :width="600"
              v-if="tipoPoloSelecionado == 'REU'"
            />

            <Circulo
              :data="dataClasses.autor"
              :options="options2"
              :height="250"
              :width="600"
              v-if="tipoPoloSelecionado == 'AUTOR'"
            />

            <Circulo
              :data="dataClasses.ambos"
              :options="options2"
              :height="250"
              :width="600"
              v-if="tipoPoloSelecionado == ''"
            />
          </div>
          <div v-else>
            <a-empty :description="null" />
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Barra from "@/components/BarraChart.vue";
import Circulo from "@/components/DoughnutChart.vue";
import Pizza from "@/components/PizzaChart.vue";

export default {
  components: {
    Barra,
    Circulo,
    Pizza,
  },

  props: {
    result: {
      type: Object,
      required: true,
    },
    resultGeral: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tipoPoloSelecionado: "",
      dataClasses: {
        reu: {
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#266EF2",
                "#679AF6",
                "#BED4FB",
                "#0E4CA1",
                "#58C4F6",
                "#3A74D1",
                "#81A1E6",
                "#7FD0F4",
                "#556070",
                "#BBBEC4",
              ],
              barPercentage: 0.5,
            },
          ],
        },
        autor: {
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#266EF2",
                "#679AF6",
                "#BED4FB",
                "#0E4CA1",
                "#58C4F6",
                "#3A74D1",
                "#81A1E6",
                "#7FD0F4",
                "#556070",
                "#BBBEC4",
              ],
              barPercentage: 0.5,
            },
          ],
        },
        ambos: {
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#266EF2",
                "#679AF6",
                "#BED4FB",
                "#0E4CA1",
                "#58C4F6",
                "#3A74D1",
                "#81A1E6",
                "#7FD0F4",
                "#556070",
                "#BBBEC4",
              ],
              barPercentage: 0.5,
            },
          ],
        },
      },
      dataUfs: {
        reu: {
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#266EF2",
                "#679AF6",
                "#BED4FB",
                "#0E4CA1",
                "#58C4F6",
                "#3A74D1",
                "#81A1E6",
                "#7FD0F4",
                "#556070",
                "#BBBEC4",
              ],
              barPercentage: 0.5,
            },
          ],
        },
        autor: {
          datasets: [
            {
              data: [32, 11],
              backgroundColor: [
                "#266EF2",
                "#679AF6",
                "#BED4FB",
                "#0E4CA1",
                "#58C4F6",
                "#3A74D1",
                "#81A1E6",
                "#7FD0F4",
                "#556070",
                "#BBBEC4",
              ],
              barPercentage: 0.5,
            },
          ],
        },
        ambos: {
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#266EF2",
                "#679AF6",
                "#BED4FB",
                "#0E4CA1",
                "#58C4F6",
                "#3A74D1",
                "#81A1E6",
                "#7FD0F4",
                "#556070",
                "#BBBEC4",
              ],
              barPercentage: 0.5,
            },
          ],
        },
      },

      dataTipos: {
        reu: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#266EF2",
                "#679AF6",
                "#BED4FB",
                "#0E4CA1",
                "#58C4F6",
                "#3A74D1",
                "#81A1E6",
                "#7FD0F4",
                "#556070",
                "#BBBEC4",
              ],
              barPercentage: 0.5,
            },
          ],
        },
        autor: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#266EF2",
                "#679AF6",
                "#BED4FB",
                "#0E4CA1",
                "#58C4F6",
                "#3A74D1",
                "#81A1E6",
                "#7FD0F4",
                "#556070",
                "#BBBEC4",
              ],
              barPercentage: 0.5,
            },
          ],
        },
        ambos: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#266EF2",
                "#679AF6",
                "#BED4FB",
                "#0E4CA1",
                "#58C4F6",
                "#3A74D1",
                "#81A1E6",
                "#7FD0F4",
                "#556070",
                "#BBBEC4",
              ],
              barPercentage: 0.5,
            },
          ],
        },
      },

      dataPeriodos: {
        reu: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#266EF2",
                "#679AF6",
                "#BED4FB",
                "#0E4CA1",
                "#58C4F6",
                "#3A74D1",
                "#81A1E6",
                "#7FD0F4",
                "#556070",
                "#BBBEC4",
              ],
              barPercentage: 0.5,
            },
          ],
        },
        autor: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#266EF2",
                "#679AF6",
                "#BED4FB",
                "#0E4CA1",
                "#58C4F6",
                "#3A74D1",
                "#81A1E6",
                "#7FD0F4",
                "#556070",
                "#BBBEC4",
              ],
              barPercentage: 0.5,
            },
          ],
        },
        ambos: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [
                "#266EF2",
                "#679AF6",
                "#BED4FB",
                "#0E4CA1",
                "#58C4F6",
                "#3A74D1",
                "#81A1E6",
                "#7FD0F4",
                "#556070",
                "#BBBEC4",
              ],
              barPercentage: 0.5,
            },
          ],
        },
      },

      options2: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: "#fff",
          },
          legend: {
            display: true,
            position: "left",
            labels: {
              font: {
                size: 9,
                weight: "bold",
              },
            },
          },
        },
      },
      optionsPizza: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            //display: false,
            // anchor: "end",
            // align: "end",
            // offset: -4,
            color: "#fff",
          },
          legend: {
            display: true,
            position: "left",
            labels: {
              font: {
                size: 11,
                weight: "bold",
              },
            },
          },
        },
      },
      optionsBarra: {
        maintainAspectRatio: false,
        backgroundColor: "#000",

        scales: {
          x: {
            grid: {
              drawBorder: false,
              display: false,
            },

            ticks: {
              display: true,
              font: {
                weight: "bold",
              },
            },
          },
          y: {
            grid: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        responsive: false,

        plugins: {
          datalabels: {
            anchor: "end",
            align: "end",
            offset: -4,
            color: "#0092FF",
            formatter: (value, old) => {
              return parseInt(value).toLocaleString("pt-BR");
            },
          },
          legend: {
            display: false,
            position: "bottom",

            labels: {
              font: {
                size: 11,
                weight: "bold",
              },
            },
          },
        },
        layout: {
          padding: {
            top: 15,
          },
        },
      },

      optionsPeriodo: {
        maintainAspectRatio: false,
        backgroundColor: "#000",

        scales: {
          x: {
            grid: {
              drawBorder: false,
              display: false,
            },

            ticks: {
              display: true,
              font: {
                weight: "bold",
              },
            },
          },
          y: {
            grid: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
        responsive: false,

        plugins: {
          datalabels: {
            anchor: "end",
            align: "end",
            offset: -4,
            color: "#0092FF",
            formatter: (value, old) => {
              return parseInt(value).toLocaleString("pt-BR");
            },
          },
          legend: {
            display: false,
            position: "bottom",

            labels: {
              font: {
                size: 11,
                weight: "bold",
              },
            },
          },
        },
        layout: {
          padding: {
            top: 15,
          },
        },
      },
      cores: [
        "#266EF2",
        "#679AF6",
        "#BED4FB",
        "#0E4CA1",
        "#58C4F6",
        "#3A74D1",
        "#81A1E6",
        "#7FD0F4",
        "#556070",
        "#BBBEC4",
      ],
    };
  },

  watch: {
    result: {
      handler() {
        this.montarGraficos();
      },
      deep: true,
    },
  },

  created() {
    this.montarGraficos();
  },

  methods: {
    formatarPalavra(valor) {
      if (valor == "REU") {
        return "Réu";
      } else if (valor == "AUTOR") {
        return "Autor";
      } else {
        return valor;
      }
    },

    formatarMilharBr(valor) {
      return valor.toLocaleString("pt-BR");
    },

    selecionarTipoPolo(value) {
      if (this.result.polos.find((p) => p.nome == value)?.qtd) {
        if (this.tipoPoloSelecionado == value) {
          this.tipoPoloSelecionado = "";
        } else {
          this.tipoPoloSelecionado = value;
        }
      } else {
        return false;
      }
    },

    montarGraficos() {
      this.dataUfs.ambos.datasets[0].data = this.result.ufs
        .filter((item) => item.qtdAmbos > 0)
        .map((item) => item.qtdAmbos);
      this.dataUfs.reu.datasets[0].data = this.result.ufs
        .filter((item) => item.qtdReu > 0)
        .map((item) => item.qtdReu);
      this.dataUfs.autor.datasets[0].data = this.result.ufs
        .filter((item) => item.qtdAutor > 0)
        .map((item) => item.qtdAutor);

      this.dataUfs.ambos.labels = this.result.ufs
        .filter((item) => item.qtdAmbos > 0)
        .map((item) => item.nome);
      this.dataUfs.reu.labels = this.result.ufs
        .filter((item) => item.qtdReu > 0)
        .map((item) => item.nome);
      this.dataUfs.autor.labels = this.result.ufs
        .filter((item) => item.qtdAutor > 0)
        .map((item) => item.nome);

      this.dataClasses.ambos.datasets[0].data = this.result.classes
        .filter((item) => item.qtdAmbos > 0)
        .map((item) => item.qtdAmbos);
      this.dataClasses.reu.datasets[0].data = this.result.classes
        .filter((item) => item.qtdReu > 0)
        .map((item) => item.qtdReu);
      this.dataClasses.autor.datasets[0].data = this.result.classes
        .filter((item) => item.qtdAutor > 0)
        .map((item) => item.qtdAutor);

      this.dataClasses.ambos.labels = this.result.classes
        .filter((item) => item.qtdAmbos > 0)
        .map((item) => item.nome);
      this.dataClasses.reu.labels = this.result.classes
        .filter((item) => item.qtdReu > 0)
        .map((item) => item.nome);
      this.dataClasses.autor.labels = this.result.classes
        .filter((item) => item.qtdAutor > 0)
        .map((item) => item.nome);

      this.dataTipos.ambos.datasets[0].data = this.result.tipos
        .filter((item) => item.qtdAmbos > 0)
        .map((item) => item.qtdAmbos);
      this.dataTipos.reu.datasets[0].data = this.result.tipos
        .filter((item) => item.qtdReu > 0)
        .map((item) => item.qtdReu);
      this.dataTipos.autor.datasets[0].data = this.result.tipos
        .filter((item) => item.qtdAutor > 0)
        .map((item) => item.qtdAutor);

      this.dataTipos.ambos.labels = this.result.tipos
        .filter((item) => item.qtdAmbos > 0)
        .map((item) => item.nome);
      this.dataTipos.reu.labels = this.result.tipos
        .filter((item) => item.qtdReu > 0)
        .map((item) => item.nome);
      this.dataTipos.autor.labels = this.result.tipos
        .filter((item) => item.qtdAutor > 0)
        .map((item) => item.nome);

      const periodos = ["6 Meses", "2 Anos", "15 Anos"];
      const variaveis = ["processos6Meses", "processos2Anos", "processosEver"];

      this.dataPeriodos.autor.labels = ["6 Meses", "2 Anos", "15 Anos"];
      this.dataPeriodos.reu.labels = ["6 Meses", "2 Anos", "15 Anos"];
      this.dataPeriodos.ambos.labels = ["6 Meses", "2 Anos", "15 Anos"];

      this.dataPeriodos.autor.datasets[0].data = [
        this.resultGeral.processos6Meses?.polos.find((p) => p.nome == "AUTOR")
          ?.qtd || 0,
        this.resultGeral.processos2Anos?.polos.find((p) => p.nome == "AUTOR")
          ?.qtd || 0,
        this.resultGeral.processosEver?.polos.find((p) => p.nome == "AUTOR")
          ?.qtd || 0,
      ];

      this.dataPeriodos.reu.datasets[0].data = [
        this.resultGeral.processos6Meses?.polos.find((p) => p.nome == "REU")
          ?.qtd || 0,
        this.resultGeral.processos2Anos?.polos.find((p) => p.nome == "REU")
          ?.qtd || 0,
        this.resultGeral.processosEver?.polos.find((p) => p.nome == "REU")
          ?.qtd || 0,
      ];

      this.dataPeriodos.ambos.datasets[0].data = [
        (this.resultGeral.processos6Meses?.polos.find((p) => p.nome == "AUTOR")
          ?.qtd || 0) +
          (this.resultGeral.processos6Meses?.polos.find((p) => p.nome == "REU")
            ?.qtd || 0),

        (this.resultGeral.processos2Anos?.polos.find((p) => p.nome == "AUTOR")
          ?.qtd || 0) +
          (this.resultGeral.processos2Anos?.polos.find((p) => p.nome == "REU")
            ?.qtd || 0),

        (this.resultGeral.processosEver?.polos.find((p) => p.nome == "AUTOR")
          ?.qtd || 0) +
          (this.resultGeral.processosEver?.polos.find((p) => p.nome == "REU")
            ?.qtd || 0),
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.card-interno {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .titulo-interno {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #0302294d;
    font-family: "Inter-bold";
  }

  .valor-interno {
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #68677f;
    font-family: "Inter-bold";
  }
}

.card-pequeno-botao {
  width: 100%;
  height: 125px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 #00000014;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.card-pequeno-botao-desabilitado {
  width: 100%;
  height: 125px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 #00000014;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  user-select: none;
}

.card-pequeno-botao-selecionado {
  width: 100%;
  height: 125px;
  border-radius: 10px;
  background-color: #679af6;
  box-shadow: 0 2px 6px 0 #00000014;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;

  .titulo-card-pequeno {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #fff;
    font-family: "Inter-bold";
  }

  .valor-card-pequeno {
    font-size: 48px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #fff;
    margin-top: 10px;
    font-family: "Inter-bold";
  }
}

.card-pequeno-botao:hover {
  width: 100%;
  height: 125px;
  border-radius: 10px;
  background-color: #6799f65e;
  box-shadow: 0 2px 6px 0 #00000014;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .titulo-card-pequeno {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #fff;
    font-family: "Inter-bold";
  }

  .valor-card-pequeno {
    font-size: 48px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.02em;
    margin-top: 10px;
    text-align: left;
    color: #fff;
    font-family: "Inter-bold";
  }
}

.card-pequeno {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 #00000014;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titulo-card-pequeno {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #0302294d;
  font-family: "Inter-bold";
}

.valor-card-pequeno {
  font-size: 46px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #68677f;
  margin-top: 10px;
  font-family: "Inter-bold";
}

.card-medio {
  width: 100%;
  height: 350px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 #00000014;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.card-medio-tipo {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 #00000014;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.titulo-card-medio {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #0302294d;
  font-family: "Inter-bold";
  margin-bottom: 20px;
}

.filtro {
  width: 100%;
  height: 60px;
  border-radius: 10px;
  padding: 12px 20px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 #00000014;
  margin-top: 20px;
}
</style>
