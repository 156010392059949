<template>
  <div class="alternativas" v-if="alternativas.length == 5">
    <div
      class="opcao-resposta"
      v-for="(alternativa, i) in alternativas"
      :key="i"
    >
      <input
        type="radio"
        :id="i"
        :name="i"
        :value="alternativa.valor"
        class="radio-resposta"
        v-model="opcaoSelecionada"
      />
      <label :for="i" class="label-resposta">
        {{ alternativa.titulo }}
      </label>
    </div>
  </div>
  <div class="alternativas-check" v-else>
    <template v-for="(alternativa, i) in alternativas" :key="i">
      <div class="opcao-resposta">
        <input
          type="checkbox"
          :id="i"
          :name="i"
          :value="alternativa.valor"
          class="radio-resposta"
          v-model="arraySelecionados"
        />
        <label :for="i" class="label-resposta">
          {{ alternativa.titulo }}
        </label>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    alternativas: {
      type: Array,
      default: () => [],
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      opcaoSelecionada: null,
      arraySelecionados: [],
      combinacaoRespostas: "Muito Baixo",
    };
  },

  watch: {
    opcaoSelecionada() {
      this.$emit("update:modelValue", this.opcaoSelecionada);
    },
    arraySelecionados(value, oldValue) {
      if (
        value.find((item) => item == "9") &&
        !oldValue.find((item) => item == "9")
      ) {
        this.arraySelecionados = ["9"];

        this.combinacaoRespostas = "Muito Baixo";
        this.$emit("update:modelValue", "Muito Baixo");
      } else if (value.length > 1 && oldValue.find((item) => item == "9")) {
        this.arraySelecionados = value.filter((item) => item !== "9");
      } else {
        switch (value?.length) {
          case 1:
            this.combinacaoRespostas = "Muito Baixo";
            this.$emit("update:modelValue", "Baixo");
            break;
          case 2:
            this.combinacaoRespostas = "Baixo";
            this.$emit("update:modelValue", "Baixo");
            break;
          case 3:
            this.combinacaoRespostas = "Mediado";
            this.$emit("update:modelValue", "Mediano");
            break;
          case 4:
            this.combinacaoRespostas = "Alto";
            this.$emit("update:modelValue", "Alto");
            break;
          case 5:
            this.combinacaoRespostas = "Muito Alto";
            this.$emit("update:modelValue", "Muito Alto");
            break;
          case 6:
            this.combinacaoRespostas = "Muito Alto";
            this.$emit("update:modelValue", "Muito Alto");
            break;
          case 7:
            this.combinacaoRespostas = "Muito Alto";
            this.$emit("update:modelValue", "Muito Alto");
            break;
          case 8:
            this.combinacaoRespostas = "Muito Alto";
            this.$emit("update:modelValue", "Muito Alto");
            break;
          case 9:
            this.combinacaoRespostas = "Muito Alto";
            this.$emit("update:modelValue", "Muito Alto");
            break;
          default:
            this.combinacaoRespostas = "Muito Baixo";
            this.$emit("update:modelValue", "Muito baixo");
        }
      }
    },

    modelValue: {
      immediate: true,
      handler(newValue) {
        this.opcaoSelecionada = newValue;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.alternativas {
  width: 100%;

  .opcao-resposta {
    .radio-resposta {
      display: none;
    }

    .label-resposta {
      display: block;
      width: 100%;
      //padding: 18px 0;
      height: 60px;
      background-color: #163150;
      border: 1px solid #ffffff;
      color: #000000;
      font-family: "Inter";
      font-size: 20px;
      font-weight: 500;
      line-height: 60px;
      text-align: center;
      color: #fff;
      margin-bottom: 20px;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
    }

    .label-resposta:hover {
      background-color: #0195ff31;
    }

    .radio-resposta:checked + .label-resposta {
      border: 1px solid #0194ff;
      background-color: #0194ff;
      color: #ffffff;
    }
  }
}

.alternativas-check {
  width: 100%;

  .opcao-resposta {
    .radio-resposta {
      position: relative;
      top: 40px;
      left: 10px;
      display: true;
      width: 20px;
      height: 20px;
      appearance: none;
      border: 2px solid #ccc;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s;
    }

    .radio-resposta:checked::after {
      content: "✔";
      color: #fff;
      font-size: 32px;
      line-height: 15px;
      position: absolute;
      top: -3px;
      left: -3px;
    }

    /* Hover para melhorar a experiência do usuário */
    .radio-resposta:hover {
      border-color: #fff;
    }

    .label-resposta {
      display: block;
      width: 100%;
      //padding: 18px 0;
      height: 50px;
      background-color: #163150;
      border: 1px solid #ffffff;
      color: #000000;
      font-family: "Inter";
      font-size: 20px;
      font-weight: 500;
      line-height: 50px;
      text-align: center;
      color: #fff;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
    }

    .label-resposta:hover {
      background-color: #0195ff31;
    }

    .radio-resposta:checked + .label-resposta {
      border: 1px solid #0194ff;
      background-color: #0194ff;
      color: #ffffff;
    }
  }
}
</style>
