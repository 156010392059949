export default ({ instance }) => {
  return {
    autenticarEmailSenha(usuario) {
      return instance({
        method: "post",
        url: "autenticacao/autenticar-usuario",
        data: usuario,
      });
    },
    consultarCadastros() {
      return instance({
        method: "get",
        url: "quiz/todos-cadastros",
      });
    },
    exportarCadastros() {
      return instance({
        method: "get",
        url: "quiz/exportar-cadastros",
        responseType: "blob",
      });
    },
  };
};
