<template>
  <button class="botao">
    <slot></slot>
  </button>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.botao {
  height: 66px;
  width: 100%;
  background-color: #0194ff;
  color: #fff;
  font-size: 20px;
  border: none;
  cursor: pointer;
  border: 1px solid #0194ff;
  font-family: "Inter-bold";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.015em;
  border-radius: 8px;
}

.botao:hover {
  background-color: #0195ff;
}

.botao:disabled {
  background-color: #0195ff6b;
  color: #fff;
  border: 1px solid #0195ff9c;
  cursor: not-allowed;
}
</style>
