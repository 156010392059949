<template>
  <a-row type="flex" justify="center" align="middle" :gutter="20">
    <a-col :span="10">
      <div class="card-consulta">
        <div class="texto-consulta-npu">Consulte o êxito do seu processo</div>

        <div class="area-input-npu">
          <a-input-search
            maxLength="25"
            v-model:value="npu"
            v-mask="['#######-##.####.#.##.####']"
            placeholder="Digite a NPU"
            enter-button="Buscar"
            size="large"
            :loading="loadingPreditivo"
            :search-disabled="true"
            class="input-npu"
            @search="pesquisarNpu(npu)"
          />
        </div>
      </div>

      <div class="card-carrossel" v-if="processos.length == 0">
        <div>
          <div>
            <div class="area-processos">
              <div class="area-info">
                <div class="titulo">Processo distribuído</div>
                <div class="valor" style="z-index: 999">
                  <a-skeleton-input
                    :active="true"
                    size="small"
                    shape="default"
                    style="width: 100%"
                    :block="true"
                  />
                </div>
              </div>

              <hr class="separador" />

              <div class="area-info">
                <div class="titulo">Classe/Assunto:</div>
                <div class="valor">
                  <div class="valor" style="z-index: 999">
                    <a-skeleton-input
                      :active="true"
                      size="small"
                      shape="default"
                      style="width: 100%"
                      :block="true"
                    />
                  </div>
                </div>
              </div>

              <hr class="separador" />

              <div class="area-info">
                <div class="titulo">Valor</div>
                <div class="valor">
                  <div class="valor" style="z-index: 999">
                    <a-skeleton-input
                      :active="true"
                      size="small"
                      shape="default"
                      style="width: 50px"
                      :block="true"
                    />
                  </div>
                </div>
              </div>

              <hr class="separador" />

              <div class="area-info">
                <div class="titulo">Vara/Comarca</div>
                <div class="valor">
                  <div class="valor" style="z-index: 999">
                    <a-skeleton-input
                      :active="true"
                      size="small"
                      shape="default"
                      style="width: 100%"
                      :block="true"
                    />
                  </div>
                </div>
              </div>

              <hr class="separador" />
            </div>
          </div>
        </div>

        <div
          v-if="
            processos.length > 1 && Object.keys(processoPesquisado).length === 0
          "
          class="setas-carrossel"
        >
          <arrow-left-outlined
            @click="currentSlide--"
            class="botao-carrossel"
          />

          <arrow-right-outlined
            @click="currentSlide++"
            class="botao-carrossel"
          />
        </div>
      </div>

      <div class="card-carrossel" v-else>
        <Carousel :itemsToShow="qtdCards" wrapAround v-model="currentSlide">
          <Slide
            v-for="processo in processos"
            :key="processo.numeracaoProcessualUnica"
          >
            <div class="area-processos">
              <div class="area-info">
                <div class="titulo">
                  Processo distribuído há
                  {{ horasDecorridas(processo.horaMinuto) }}h e
                  {{ minutosDecorridos(processo.horaMinuto) }} minutos
                </div>
                <div class="valor" style="z-index: 999">
                  {{ processo.numeracaoProcessualUnica }}
                  <SearchOutlined
                    :rotate="90"
                    id="icone-pesquisa"
                    @click="pesquisarNpu(processo.numeracaoProcessualUnica)"
                  />
                </div>
              </div>

              <hr class="separador" />

              <div class="area-info">
                <div class="titulo">Classe/Assunto:</div>
                <div class="valor">{{ processo.tipoProcesso }}</div>
              </div>

              <hr class="separador" />

              <div class="area-info">
                <div class="titulo">Valor</div>
                <div class="valor">{{ formatarValorReal(processo.valor) }}</div>
              </div>

              <hr class="separador" />

              <div class="area-info">
                <div class="titulo">Vara/Comarca</div>
                <div class="valor">{{ processo.vara }}</div>
              </div>

              <hr class="separador" />
            </div>
          </Slide>

          <template #addons>
            <Pagination />
          </template>
        </Carousel>

        <div
          v-if="
            processos.length > 1 && Object.keys(processoPesquisado).length === 0
          "
          class="setas-carrossel"
        >
          <arrow-left-outlined
            @click="currentSlide--"
            class="botao-carrossel"
          />

          <arrow-right-outlined
            @click="currentSlide++"
            class="botao-carrossel"
          />
        </div>
      </div>
    </a-col>

    <a-col :span="14">
      <div class="card-resultado">
        <div class="texto-consulta-npu">Resultado Preditivo</div>
        <div class="area-graficos">
          <a-row type="flex" justify="start" align="middle">
            <a-col :span="12">
              <Grafico
                :data="data"
                :options="options"
                v-if="!loadingPreditivo"
              />
              <div class="skeleton-grafico" v-else>
                <a-skeleton-button
                  :active="true"
                  size="large"
                  shape="circle"
                  style="width: 100%"
                />
              </div>
            </a-col>

            <a-col :span="10">
              <div class="area-percentual">
                <div class="area-status">
                  <div class="status-proc">Procedente</div>
                  <div class="valor" v-if="!loadingPreditivo">
                    {{ formatarPercentual(exito.procedente) }}
                  </div>
                  <div class="valor" v-else>
                    <a-skeleton-button
                      :active="true"
                      size="small"
                      shape="default"
                    />
                  </div>
                </div>

                <div class="area-status">
                  <div class="status-parc">Parcialmente Pro.</div>
                  <div class="valor" v-if="!loadingPreditivo">
                    {{ formatarPercentual(exito.parcialmenteProcedente) }}
                  </div>

                  <div class="valor" v-else>
                    <a-skeleton-button
                      :active="true"
                      size="small"
                      shape="default"
                    />
                  </div>
                </div>

                <div class="area-status">
                  <div class="status-imp">Improcedente</div>
                  <div class="valor" v-if="!loadingPreditivo">
                    {{ formatarPercentual(exito.improcedente) }}
                  </div>
                  <div class="valor" v-else>
                    <a-skeleton-button
                      :active="true"
                      size="small"
                      shape="default"
                    />
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>

        <div class="area-analise">
          <div v-if="predicao != null && !loadingPreditivo">
            <p v-if="tipoExitoJulgado !== 'improcedente'">
              Se o mérito do processo
              <span class="destaque">{{ numNpu }}</span> for julgado, o
              resultado mais esperado será
              <span class="destaque">
                {{ formatarIndExito(tipoExitoJulgado) }}
              </span>
              , com probabilidade de
              <span class="destaque">
                {{ (100 * probabilidadeExito[tipoExitoJulgado]).toFixed(2) }}
                %
              </span>
              de acontecer e com um valor de condenação estimado em
              <span class="destaque">{{ formatarValorReal(valorAcao) }}</span>
              .
            </p>
            <p v-if="tipoExitoJulgado === 'improcedente'">
              Se o mérito do processo
              <span class="destaque">{{ numNpu }}</span> for julgado, o
              resultado mais esperado será
              <span class="destaque">
                {{ formatarIndExito(tipoExitoJulgado) }}
              </span>
              , com probabilidade de
              <span class="destaque">
                {{ (100 * probabilidadeExito[tipoExitoJulgado]).toFixed(2) }}
                %
              </span>
              de acontecer.
            </p>
            <p>
              Entre
              <span class="destaque">{{ formatarData(dataInicial) }}</span> e
              <span class="destaque">{{ formatarData(dataFinal) }}</span
              >,
              <span class="destaque">
                {{ formatarPercentual(valorAcordoProb) }}
              </span>
              dos processos com características semelhantes terminaram em
              <span class="destaque">Acordo</span>.
            </p>
          </div>
          <div v-else>
            <a-skeleton active :paragraph="{ rows: 5 }" />
          </div>
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import {
  SearchOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons-vue";
import Grafico from "@/components/DoughnutChart.vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { format } from "date-fns";
import API from "@/api";

export default {
  name: "App",
  components: {
    SearchOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    Slide,
    Carousel,
    Navigation,
    Pagination,
    Grafico,
  },

  data() {
    return {
      data: {
        labels: ["Procedente", "Parcialmente Pro.", "Improcedente"],
        datasets: [
          {
            data: [0, 0, 0],
            backgroundColor: ["#266EF2", "#679AF6", "#BED4FB"],
            radius: 120,
            borderRadius: 10,
            offset: 10,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: "55%",
        plugins: {
          tooltip: {
            callbacks: {
              label: function (a) {
                return a.parsed + "%";
              },
            },
          },
          datalabels: {
            color: "#FFF",
            font: { size: 12, weight: "bold" },
            formatter: (a) => (a < 10 ? "" : a + "%"),
          },
          legend: { display: false },
        },
      },
      npu: "",
      loadingPreditivo: false,
      currentSlide: 0,
      qtdCards: 1,
      processoPesquisado: {},
      exito: {
        procedente: 0,
        parcialmenteProcedente: 0,
        improcedente: 0,
      },
      predicao: null,
      processos: [],
      numNpu: "",
      tipoExitoJulgado: "",
      probabilidadeExito: {},
      valorAcao: 0,
      dataInicial: "",
      dataFinal: "",
      valorAcordoProb: 0,
    };
  },

  watch: {},

  mounted() {
    window.history.replaceState(null, null, "/");
    this.consultarAmostraProcessos();
    this.pesquisarNpu("0000001-15.2018.8.01.0013");
  },

  methods: {
    percentual(a) {
      return (100 * a).toFixed(2);
    },

    formatarData(data) {
      return format(new Date(data), "dd/MM/yyyy");
    },

    montarResultado() {
      this.data.datasets[0].data = [
        this.percentual(this.predicao?.ind_exito.procedente),
        this.percentual(this.predicao?.ind_exito.parcialmente_procedente),
        this.percentual(this.predicao?.ind_exito.improcedente),
      ];

      (this.numNpu = this.predicao?.num_npu),
        (this.tipoExitoJulgado = this.predicao?.ind_exito?.class),
        (this.probabilidadeExito = this.predicao?.ind_exito),
        (this.valorAcao = this.predicao?.valores_finais?.filter(
          (a) => a.exito == this.tipoExitoJulgado
        )[0]?.valor_final_predicao),
        (this.dataInicial = this.predicao?.acordo?.data_inicial),
        (this.dataFinal = this.predicao?.acordo?.data_final),
        (this.valorAcordoProb = this.predicao?.acordo?.sentenca),
        (this.exito.procedente = this.predicao?.ind_exito?.procedente),
        (this.exito.parcialmenteProcedente =
          this.predicao?.ind_exito?.parcialmente_procedente),
        (this.exito.improcedente = this.predicao?.ind_exito?.improcedente);
    },

    formatarIndExito(a) {
      return "procedente" === a
        ? "Procedente"
        : "parcialmente_procedente" === a
        ? "Parcialmente Procedente"
        : "Improcedente";
    },

    formatarPercentual(a) {
      return (100 * a).toFixed(2) + "%";
    },

    consultarAmostraProcessos() {
      API.amostraProcessos().then((response) => {
        this.processos = response.data;
      });
    },

    formatarValorReal(valor) {
      let numero = parseFloat(valor);

      return numero.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },

    horasDecorridas(a) {
      let e = a.split(":");
      return e[0];
    },

    minutosDecorridos(a) {
      let e = a.split(":");
      return e[1];
    },

    pesquisarNpu(npu) {
      this.loadingPreditivo = true;
      API.preditivoAnalytics(npu)
        .then((response) => {
          this.predicao = response.data;
          this.montarResultado();
          this.loadingPreditivo = false;
        })
        .catch(() => {
          this.loadingPreditivo = false;
          this.$message.error("Processo não encontrado");
          this.npu = "";
        });
    },
  },
};
</script>

<style lang="less" scoped>
.card-consulta {
  width: 100%;
  height: 160px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 #00000014;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.card-resultado {
  width: 100%;
  height: 623px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 #00000014;
  padding: 35px 30px;
  background-color: #fff;
}

.card-carrossel {
  width: 100%;
  height: 441px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 #00000014;
  margin-top: 20px;
  padding: 0 20px;
  background-color: #fff;
  padding: 30px;
}

.area-processos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 350px;

  .separador {
    width: 100%;
    margin: 10px 0;
    border: 1px solid #ececec;
  }

  .area-info {
    width: 100%;
    text-align: left;
    max-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .titulo {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      color: #0302294d;
    }

    .valor {
      font-family: "Inter-bold";
      font-size: 18px;
      font-weight: 700;
      line-height: 21.78px;
      color: #03022999;
    }
  }
}

.area-input-npu {
  width: 100%;
  margin-top: 20px;
}

.texto-consulta-npu {
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
  text-align: left;
  color: #03022999;
  font-family: "Inter-extra-bold";
  user-select: none;
}

:deep(.ant-input-group > .ant-input:first-child) {
  background-color: #fafafb;
  border: 2px solid #fafafb;
  font-size: 14px;
  line-height: 22px;
  color: #0302294d;
  font-family: "Inter-bold";
}

:deep(
    .ant-input-search
      > .ant-input-group
      > .ant-input-group-addon:last-child
      .ant-input-search-button
  ) {
  background-color: #266ef2;
  border: 1px solid #266ef2;
  padding: 0 40px;
  font-size: 14px;
  font-family: "Inter-bold";
}

.area-graficos {
  margin-top: 20px;

  .area-percentual {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .area-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      width: 100%;

      .status-proc {
        color: #03022999;
        font-size: 18px;
        font-weight: 700;
        font-family: "Inter-extra-bold";
        text-align: left;
        user-select: none;
      }

      .status-proc::before {
        content: "";
        background-color: #266ef2;
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .status-parc {
        color: #03022999;
        font-size: 18px;
        font-weight: 700;
        font-family: "Inter-extra-bold";
        text-align: left;
        user-select: none;
      }

      .status-parc::before {
        content: "";
        background-color: #6092ee;
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .status-imp {
        color: #03022999;
        font-size: 18px;
        font-weight: 700;
        font-family: "Inter-extra-bold";
        text-align: left;
        user-select: none;
      }

      .status-imp::before {
        content: "";
        background-color: #acc2ea;
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .valor {
        font-size: 18px;
        font-weight: 600;
        font-family: "Inter";
        color: #9291a5af;
        text-align: right;
        user-select: none;
      }
    }
  }
}

.area-analise {
  p {
    font-size: 18px;
    line-height: 27px;
    text-align: justify;
    font-family: "Inter";
    color: #0302294d;
  }

  span {
    font-family: "Inter-bold";
    color: #03022999;
  }
}

:deep(.carousel__pagination-button::after) {
  background-color: #fff;
  border: 1.2px solid #03022999;
  height: 9px;
  width: 9px;
  border-radius: 50%;
}

:deep(.carousel__pagination-button--active::after) {
  background-color: #68677f;
}

:deep(
    .carousel__pagination-button:hover::after,
    .carousel__pagination-button--active::after
  ) {
  background-color: #928b8b44;
}

.setas-carrossel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -16.5px;
}

.botao-carrossel {
  position: relative;
  z-index: 999;
}

.skeleton-grafico {
  width: 100%;
  height: 300px;
  display: flex;
  text-align: center;
  align-items: center;
}

:deep(
    .ant-skeleton.ant-skeleton-element
      .ant-skeleton-button-lg.ant-skeleton-button-circle
  ) {
  width: 230px;
  height: 230px;
}
</style>
