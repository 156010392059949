<template>
  <div>
    <a-row type="flex" justify="center" align="middle">
      <a-col :span="24">
        <div>
          <a-row
            type="flex"
            justify="space-between"
            align="middle"
            class="card-grande"
          >
            <a-col :span="14" class="texto-maior-base">
              A maior base de processos do Brasil
            </a-col>

            <a-col
              :span="10"
              class="quantidade-maior-base"
              v-if="totalProcessos > 0"
            >
              {{ formatarMilharBr(totalProcessos) }}
              <img src="@/assets/images/icone-grafico.png" alt="" />
            </a-col>

            <a-col
              :span="10"
              class="quantidade-maior-base"
              style="margin-top: -40px"
              v-else
            >
              <div class="valor-card-pequeno">
                <a-skeleton-input
                  :active="true"
                  size="large"
                  shape="default"
                  style="width: 100%"
                  :block="true"
                />
              </div>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>

    <a-row type="flex" justify="space-between" align="middle" :gutter="20">
      <a-col :span="8">
        <div class="card-pequeno">
          <div class="titulo-card-pequeno">Novos processos</div>

          <div class="valor-card-pequeno" v-if="qtdProcessos > 0">
            {{ formatarMilharBr(qtdProcessos) }}
          </div>
          <div class="valor-card-pequeno" v-else>
            <a-skeleton-input
              :active="true"
              size="large"
              shape="default"
              style="width: 100%"
              :block="true"
            />
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="card-pequeno">
          <div class="titulo-card-pequeno">Novos Andamentos</div>

          <div class="valor-card-pequeno" v-if="qtdAndamentos > 0">
            {{ formatarMilharBr(qtdAndamentos) }}
          </div>
          <div class="valor-card-pequeno" v-else>
            <a-skeleton-input
              :active="true"
              size="large"
              shape="default"
              style="width: 100%"
              :block="true"
            />
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div class="card-pequeno">
          <div class="titulo-card-pequeno">Novas Publicações</div>

          <div class="valor-card-pequeno" v-if="qtdPublicacoes > 0">
            {{ formatarMilharBr(qtdPublicacoes) }}
          </div>
          <div class="valor-card-pequeno" v-else>
            <a-skeleton-input
              :active="true"
              size="large"
              shape="default"
              style="width: 100%"
              :block="true"
            />
          </div>
        </div>
      </a-col>
    </a-row>

    <a-row type="flex" justify="space-between" align="middle" class="card-uf">
      <a-col :span="24">
        <div class="header-materias">
          <a-row type="flex" justify="space-between" align="middle">
            <a-col :span="10">
              <div class="titulo">UF <a-tag class="top-10">Top 10</a-tag></div>
            </a-col>

            <a-col :class="14">
              <a-radio-group
                v-model:value="selecionado"
                button-style="solid"
                class="radio"
              >
                <a-radio-button value="federal">
                  Justiça Federal
                </a-radio-button>
                <a-radio-button value="estadual">
                  Justiça Estadual
                </a-radio-button>
                <a-radio-button value="trabalho">
                  Justiça do Trabalho
                </a-radio-button>
              </a-radio-group>
            </a-col>
          </a-row>
        </div>
      </a-col>

      <div class="conteudo">
        <Barra
          :data="result.federal"
          :options="optionsBarra"
          :height="200"
          :width="900"
          v-if="selecionado === 'federal' && !loadingUf.federal"
        />

        <Barra
          :data="result.estadual"
          :options="optionsBarra"
          :height="200"
          :width="900"
          v-else-if="selecionado === 'estadual' && !loadingUf.estadual"
        />

        <Barra
          :data="result.trabalhista"
          :options="optionsBarra"
          :height="200"
          :width="900"
          v-if="selecionado === 'trabalho' && !loadingUf.trabalhista"
        />
      </div>

      <!-- <div class="conteudo" v-else>
          <a-row
            type="flex"
            justify="center"
            align="middle"
            class="item-materia-skeleton"
            v-for="i in 10"
            :key="i"
          >
            <a-col :span="21">
              <a-skeleton
                active
                :title="false"
                :paragraph="{ rows: 1 }"
                style="width: 165%"
              />
            </a-col>
          </a-row>
        </div> -->
    </a-row>

    <a-row>
      <a-col :span="24">
        <div class="card-rodape">
          <div class="texto-rodape">
            <ClockCircleFilled class="icone-rodape" />

            Dados acumulados durante a semana da Fenalaw. Última atualização:
            {{ dataAtualizacao }}, às {{ horaAtualizacao }}.
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import API from "@/api";
import { format } from "date-fns";
import { CaretUpOutlined, ClockCircleFilled } from "@ant-design/icons-vue";
import Barra from "@/components/BarraChart.vue";

export default {
  components: {
    CaretUpOutlined,
    ClockCircleFilled,
    Barra,
  },

  data() {
    return {
      totalProcessos: 0,
      qtdPublicacoes: 0,
      qtdAndamentos: 0,
      qtdProcessos: 0,
      dataAtualizacao: "",
      horaAtualizacao: "",
      loadingUf: {
        federal: true,
        estadual: true,
        trabalho: true,
      },
      selecionado: "federal",
      total: [],
      result: {
        federal: {},

        estadual: {},

        trabalhista: {},
      },
      optionsBarra: {
        maintainAspectRatio: false,
        backgroundColor: "#000",

        scales: {
          x: {
            grid: {
              drawBorder: true,
              display: true,
            },

            ticks: {
              display: true,
              font: {
                weight: "normal",
              },
            },
          },
          y: {
            grid: {
              drawBorder: true,
              display: true,
            },
            ticks: {
              display: true,
            },
          },
        },
        responsive: false,

        plugins: {
          datalabels: {
            anchor: "end",
            align: "end",
            offset: -4,
            color: "#333333",
            formatter: (value, old) => {
              return parseInt(value).toLocaleString("pt-BR");
            },
          },
          legend: {
            display: false,
          },
        },
        layout: {
          padding: {
            top: 0,
          },
          margin: {
            top: 0,
          },
        },
      },
    };
  },

  mounted() {
    window.history.replaceState(null, null, "/");
    this.consultarUfs("federal");
    this.consultarUfs("estadual");
    this.consultarUfs("trabalhista");
    this.realizarConsultas();
  },

  methods: {
    async realizarConsultas() {
      await this.qtdTotalProcessos();
      this.qtdPublicacoesFenalaw();
      this.qtdAndamentosFenalaw();
      this.qtdProcessosFenalaw();

      this.dataAtualizacao = format(new Date(), "dd/MM/yy");
      this.horaAtualizacao = format(new Date(), "HH:mm:ss");
    },

    async qtdTotalProcessos() {
      API.qtdTodosProcessos().then((response) => {
        this.totalProcessos = response.data;
      });
    },

    async qtdPublicacoesFenalaw() {
      API.qtdPublicacoesFenalaw().then((response) => {
        this.qtdPublicacoes = response.data.qtde;
      });
    },

    async qtdAndamentosFenalaw() {
      API.qtdAndamentosFenalaw().then((response) => {
        this.qtdAndamentos = response.data.qtde;
      });
    },

    async qtdProcessosFenalaw() {
      API.qtdProcessosFenalaw().then((response) => {
        this.qtdProcessos = response.data.qtde;
      });
    },

    formatarMilharBr(valor) {
      return valor.toLocaleString("pt-BR");
    },

    consultarUfs(justica) {
      API.consultarQtdUfPorJustica(justica)
        .then((response) => {
          this.result[justica] = {
            labels: response.data.map((item) => item.uf).slice(0, 10),
            datasets: [
              {
                label: justica,
                backgroundColor: [
                  "#0194FF",
                  "#0194FFE5",
                  "#0194FFCC",
                  "#0194FFB2",
                  "#319BFA99",
                  "#0194FF80",
                  "#0194FF66",
                  "#0194FF4D",
                  "#0194FF33",
                  "#0194FF1A",
                ],
                data: response.data.map((item) => item.qtde).slice(0, 10),
                barPercentage: 0.5,
              },
            ],
          };

          this.loadingUf[justica] = false;
        })
        .catch(() => {
          this.loadingUf = false;
        });
    },

    nomeJusticaConsulta(justica) {
      switch (justica) {
        case "federal":
          return "JUSTICA FEDERAL";
        case "estadual":
          return "JUSTICA ESTADUAL";
        case "trabalho":
          return "JUSTICA DO TRABALHO";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.card-grande {
  width: 100%;
  height: 170px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 #00000014;
  padding: 0 5%;
}

.card-uf {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 #00000014;
  padding: 0 20px;
  margin-top: 20px;
}

.card-pequeno {
  width: 100%;
  height: 170px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 #00000014;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titulo-card-pequeno {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #0302294d;
  font-family: "Inter-bold";
}

.valor-card-pequeno {
  font-size: 56px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #68677f;
  margin-top: 24px;
  font-family: "Inter-bold";
}

.acressimo {
  color: #266ef2;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
}

.icone-acressimo {
  margin-right: -2px;
  margin-left: 5px;
}

.texto-maior-base {
  font-size: 32px;
  font-weight: 700;
  text-align: left;
  color: #03022999;
  font-family: "Inter-extra-bold";
}

.quantidade-maior-base {
  font-size: 60px;
  font-weight: 700;
  line-height: 72.61px;
  text-align: right;
  color: #266ef2;
  font-family: "Inter-bold";
}

.card-rodape {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 72px;
  background-color: #266ef2;
  border-radius: 10px;
  margin-top: 20px;
}

.texto-rodape {
  color: #fff;
  font-size: 20px;
  border-radius: 10px;
}

.icone-rodape {
  margin-right: 30px;
}

.header-materias {
  .titulo {
    font-family: "Inter-bold";
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    color: #03022999;
    display: flex;
    align-items: center;
  }

  .radio {
    font-family: "Inter-bold";
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: center;
    color: #ffffff;
  }

  .top-10 {
    border: none;
    border-radius: 30px;
    background-color: #266ef20d;
    color: #266ef2;
    font-family: "Inter-bold";
    padding: 3px 10px;
    margin-left: 20px;
  }
}

.conteudo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .texto {
    font-family: "Inter-bold";
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #03022999;
  }

  .quantidade {
    font-family: "Inter-bold";
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-align: right;
    color: #03022999;
  }

  .item-materia {
    margin-bottom: 10px;
  }

  .item-materia-skeleton {
    margin: 10px 0 16px 0;
  }
}

:deep(.ant-radio-button-wrapper) {
  color: #0302294d;
  font-size: 14px;
  padding: 0 30px;
  height: 35px;
  line-height: 35px;
}

:deep(.ant-radio-button-wrapper:hover) {
  color: #030229a5;
}

:deep(.ant-progress .ant-progress-text) {
  display: none;
}

.area-materias {
  width: 100%;
  height: 100%;
}
</style>
