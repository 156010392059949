<template>
  <div class="row justify-center align-center" id="k-login">
    <div>
      <div class="login-card">
        <form @submit.prevent="loginFormSubmit">
          <div class="form-group">
            <input
              type="usuario"
              id="usuario"
              v-model="formState.usuario"
              placeholder="Usuário"
              class="input-field"
              ref="usuario"
            />
          </div>

          <div class="form-group">
            <input
              :type="senhaVisivel ? 'text' : 'password'"
              id="senha"
              v-model="formState.senha"
              placeholder="Senha"
              class="input-field"
              ref="senha"
            />
          </div>

          <div class="form-group">
            <input type="checkbox" id="show-password" v-model="senhaVisivel" />
            <label for="show-password"> Mostrar senha</label>
          </div>

          <br />

          <div class="row justify-start">
            <button type="submit" class="btn-primary">Entrar</button>
          </div>
          <br />
        </form>
      </div>
    </div>
  </div>

  <footer>
    <p>&copy; Copyright 2024 - Kurier Tecnologia</p>
  </footer>
</template>

<script>
import API from "@/api";
export default {
  components: {},

  data() {
    return {
      loading: false,
      formState: {
        usuario: "",
        senha: "",
      },
      senhaVisivel: false,
      loading: false,
    };
  },

  methods: {
    loginFormSubmit() {
      this.loading = true;
      const login = {
        usuario: this.formState.usuario,
        senha: this.formState.senha,
      };
      API.autenticarEmailSenha(login)
        .then((res) => {
          if (res.status == 200) {
            this.$store.dispatch("salvarToken", res.data.token);
            this.$router.push({ name: "dadosCadastros" });
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$notification.error({
              message: "Não foi possível autenticar.",
              description: "Usuário ou senha inválidos.",
            });
          }
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
#k-login {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;

  .k-login__card {
    border-radius: 6px;
    padding: 15px 0;
  }

  .k-login__logo-kurier {
    width: 70%;
    padding-bottom: 30px;
  }
}

footer {
  background-color: #f5f5f5;
  padding: 10px 0;
  text-align: center;
}

footer p {
  font-size: 12px;
  color: #666;
}

#k-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-card {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  background-color: white;
}

.login-logo {
  max-width: 100%;
  height: auto;
}

.input-field {
  width: 100%;
  border-radius: 5px;
  height: 45px;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
}

.btn-primary {
  width: 100%;
  padding: 12px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.btn-primary:hover {
  background-color: #40a9ff;
}

.forgot-password {
  text-align: center;
  color: grey;
  font-size: 12px;
  cursor: pointer;
}

.forgot-password:hover {
  color: #1890ff;
}
</style>
