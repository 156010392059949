<template>
  <a-spin :tip="mensagemLoading" />
</template>

<script>
export default {
  data() {
    return {
      mensagemLoading: "Consultando Empresa",
      //   mensagens: [
      //     "Consultando Empresa",
      //     "Consultando Empresa",
      //     "Consultando Empresa",
      //     "Consultando Empresa",
      //     "Consultando Empresa",
      //     "Consultando Empresa",
      //     "Consultando Empresa",
      //     "Consultando Empresa",
      //     "Consultando Empresa",
      //     "Analisando Dados",
      //     "Analisando Dados",
      //     "Analisando Dados",
      //     "Analisando Dados",
      //     "Analisando Dados",
      //     "Analisando Dados",
      //     "Analisando Dados",
      //     "Analisando Dados",
      //     "Analisando Dados",
      //     "Gerando Relatórios",
      //     "Gerando Relatórios",
      //     "Gerando Relatórios",
      //     "Gerando Relatórios",
      //     "Gerando Relatórios",
      //     "Gerando Relatórios",
      //     "Gerando Relatórios",
      //     "Gerando Relatórios",
      //     "Gerando Relatórios",
      //     "Preparando Gráficos",
      //     "Preparando Gráficos",
      //     "Preparando Gráficos",
      //     "Preparando Gráficos",
      //     "Preparando Gráficos",
      //     "Preparando Gráficos",
      //     "Preparando Gráficos",
      //     "Preparando Gráficos",
      //     "Preparando Gráficos",
      //     "Só mais um momento    ",
      //     "Só mais um momento .  ",
      //     "Só mais um momento .. ",
      //     "Só mais um momento ...",
      //     "Só mais um momento    ",
      //     "Só mais um momento .  ",
      //     "Só mais um momento .. ",
      //     "Só mais um momento ...",
      //     "Só mais um momento    ",
      //     "Só mais um momento .  ",
      //     "Só mais um momento .. ",
      //     "Só mais um momento ...",
      //     "Quase lá    ",
      //     "Quase lá .  ",
      //     "Quase lá .. ",
      //     "Quase lá ...",
      //     "Quase lá    ",
      //     "Quase lá .  ",
      //     "Quase lá .. ",
      //     "Quase lá ...",
      //     "Quase lá    ",
      //     "Quase lá .  ",
      //     "Quase lá .. ",
      //     "Quase lá ...",
      //     "Quase lá    ",
      //     "Quase lá .  ",
      //     "Quase lá .. ",
      //     "Quase lá ...",
      //     "Quase lá    ",
      //     "Quase lá .  ",
      //     "Quase lá .. ",
      //     "Quase lá ...",
      //     "Quase lá    ",
      //     "Quase lá .  ",
      //     "Quase lá .. ",
      //     "Quase lá ...",
      //     "Quase lá    ",
      //     "Quase lá .  ",
      //     "Quase lá .. ",
      //     "Quase lá ...",
      //   ],
      mensagens: [
        "Consultando Empresa",
        "Analisando Dados",
        "Gerando Relatórios",
        "Preparando Gráficos",
        "Só mais um momento",
        "Quase lá",
      ],
      contador: 0,
      intervalo: null,
    };
  },

  mounted() {
    this.iniciarTroca();
  },

  beforeUnmount() {
    this.pararTroca();
  },

  methods: {
    alternarMensagem() {
      this.contador = (this.contador + 1) % this.mensagens.length;
      this.mensagemLoading = this.mensagens[this.contador];
    },
    iniciarTroca() {
      if (!this.intervalo) {
        this.intervalo = setInterval(this.alternarMensagem, 10000);
      }
    },
    pararTroca() {
      clearInterval(this.intervalo);
      this.intervalo = null;
    },
  },
};
</script>

<style></style>
